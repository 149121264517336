/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC, useState, useEffect, useRef } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { SearchFormPrimaryBlockProps, BrandSelectionProps, SeenBrandsInterface } from './SearchForm.types';
import { BrandFilter, RoomsAndGuests, SpecialRates, UsePoints } from '../../molecules';
import {
  corporateCodeFormatLengthInvalidError,
  ELEMENT_BUTTON,
  searchFormClickTrackingLoc,
} from '../../constants/lib/constants';
import { eventUtil } from '../../modules/utils';
import clsx from 'clsx';
import { useStaticDataContext } from '../../modules/context'; // AEM Labels
import { DropDownModal, InputTextField } from '@marriott/mi-ui-library';
import { SearchFormSubmitButton } from './SearchForm.primary';
import { BRANDS, FOCUS_INTERACTIONS } from '../../modules/store/store.constants';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { useCheckBreakpoint } from '../../hooks/lib/useCheckBreakpoint';
import { returnApplicableAlert } from '../../utils/src/utils';

/**
 * search form below section consist of all the
 * below molecules like rates/point and room and guest
 * @returns
 */

export const SearchSecondaryBlock: FC<SearchFormPrimaryBlockProps> = ({
  isDesktopView,
  submitAction,
  isAvailabilityScenario,
  sessionData,
}) => {
  const isMobileView = useCheckBreakpoint('viewportXS');
  const brands = useSearchFormStore((state: any) => state?.brands);
  const marriottBrands = brands?.marriottBrands;
  const [userSelectedBrands, setUserSelectedBrands] = useState<BrandSelectionProps[]>(
    marriottBrands ? marriottBrands : []
  );
  const [openPopup, setOpenPopupState] = useState(false);
  const {
    enableRedeemPoints,
    isTabbedSearchForm,
    submitCTA,
    hideDestination,
    brandCategories,
    brandsLabel,
    brandsResetCta,
    done,
    allBrandsLabel,
    brandsSelectedLabel,
    brandSelectedLabel,
    alertsListArray,
    hideBrandsField,
    disableSpecialRates,
  } = useStaticDataContext();
  const [showSecondaryForm, setShowSecondaryForm] = useState(false);
  let temporarySelectedBrandObjectArr: BrandSelectionProps[] = [];
  const searchFormTopPosition = useSearchFormStore((state: any) => state?.searchFormTopPosition);
  // function to rename the brandsLabelListItems to brands to avoid page breaking issue
  const renameBrandsKey = (brandCategoriesObj: any) => {
    const newKeyName = 'brands';
    brandCategoriesObj?.forEach((obj: any) => {
      obj[newKeyName] = [...(obj['brandsLabelListItems'] ?? [])];
      obj?.brands?.forEach((brand: any) => {
        return { ...brand, isDisabled: false };
      });
    });
    return brandCategoriesObj;
  };
  const totalBrandCount = getTotalBrandCount();
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);

  const bodyRef = useRef<HTMLBodyElement | null>(null);

  useEffect(() => {
    bodyRef.current = document.body as HTMLBodyElement;
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click target is not within the popup or the button or the icons
      if (
        isTabbedSearchForm &&
        !event.target.closest('.adv-search-brands-dropdown') &&
        !event.target.closest('.brands-filter-input-container') &&
        !event.target.closest('.brand-filters-arrow-icon')
      ) {
        setOpenPopupState(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // function to handle any brand selection changes
  const onBrandChangeHandler = (brandsInteractionData: any) => {
    const formattedSelectedBrandObjArr: BrandSelectionProps[] = [];
    brandsInteractionData?.forEach((selectedBrand: BrandSelectionProps) => {
      const brandObj: BrandSelectionProps = {
        brandTagId: '',
        brandTagTitle: '',
      };
      brandObj['brandTagId'] = selectedBrand?.brandTagId;
      brandObj['brandTagTitle'] = selectedBrand?.brandTagTitle;
      formattedSelectedBrandObjArr.push(brandObj);
    });
    temporarySelectedBrandObjectArr = formattedSelectedBrandObjArr;
    const marriottBrands = formattedSelectedBrandObjArr?.map((item: any) => item.brandTagId);
    setSearchFormState([BRANDS, FOCUS_INTERACTIONS], {
      [BRANDS]: {
        marriottBrands: marriottBrands,
      },
      [FOCUS_INTERACTIONS]: { isUserInteractWithComp: true },
    });
  };

  useEffect(() => {
    // Filter brands based on brandTagIdsToMatch
    const filteredBrandsData = renameBrandsKey(brandCategories)?.reduce((result: any, category: any) => {
      const filteredCategoryBrands = category?.brands
        ?.filter((brand: any) => marriottBrands?.includes(brand?.brandTagId))
        .map(({ ...rest }) => rest); // Extract only brandTagId and brandTagTitle
      result?.push(...(filteredCategoryBrands ?? []));
      return result;
    }, []);
    setUserSelectedBrands(filteredBrandsData);
  }, [marriottBrands]);

  // funcion to get the total number of brands
  function getTotalBrandCount() {
    let brandCount = 0;
    renameBrandsKey(brandCategories)?.forEach((brandCategoryItem: any) => {
      brandCount += brandCategoryItem?.brands?.length;
    });
    return brandCount;
  }

  // function to handle the "apply" btn action and save selected brands
  const onClickHandler = () => {
    const alreadySelectedBrands: SeenBrandsInterface = {};
    temporarySelectedBrandObjectArr.forEach(seenBrand => {
      alreadySelectedBrands[seenBrand?.brandTagId] = false;
    });
    const newResultantArr: BrandSelectionProps[] = [];
    temporarySelectedBrandObjectArr.forEach(selectedBrand => {
      if (!alreadySelectedBrands[selectedBrand?.brandTagId]) {
        newResultantArr.push(selectedBrand);
        alreadySelectedBrands[selectedBrand?.brandTagId] = true;
      }
    });
    setUserSelectedBrands(userSelectedBrands ? userSelectedBrands : newResultantArr);
    temporarySelectedBrandObjectArr = [];
  };

  // function to handle the clear btn click event
  const onClearClickHandler = () => {
    setUserSelectedBrands([]);
    setSearchFormState([BRANDS], {
      [BRANDS]: {
        marriottBrands: [],
      },
    });
  };

  useEffect(() => {
    eventUtil.on('showSecondaryForm', value => {
      setShowSecondaryForm(value);
    });
  }, [eventUtil]);

  // adding a useEffect to apply modal stylings once the popup is opened
  useEffect(() => {
    const dropdownElem = document.getElementsByClassName('adv-search-brand-field-dropdown')?.[0] as HTMLElement;
    const childDropdownElem = dropdownElem?.querySelector('.m-dropdown-children') as HTMLElement;
    if (dropdownElem && isTabbedSearchForm && childDropdownElem) {
      dropdownElem.style.position = 'static';
      dropdownElem.style.background = 'white';
      dropdownElem.scrollTop = 0;

      childDropdownElem.style.position = 'static';
      childDropdownElem.style.background = 'white';
      childDropdownElem.scrollTop = 0;

      const footerElem = dropdownElem?.getElementsByClassName('m-dropdown-action-bar')?.[0] as HTMLElement;
      if (footerElem && isTabbedSearchForm) {
        footerElem.style.background = 'white';
        footerElem.style.position = 'sticky';
        footerElem.style.bottom = '0';
        if (isMobileView && !isDesktopView && bodyRef.current) {
          footerElem.style.paddingLeft = '0';
          footerElem.style.paddingRight = '0';
          bodyRef.current.style.overflow = 'hidden';
        }
      }
    }
    if (!openPopup && isTabbedSearchForm && bodyRef.current) {
      bodyRef.current.style.overflow = 'auto';
    }
  }, [openPopup]);
  const brandsCompRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null); //ref to the last element of the search field, to be used in dropdown modal to identify the last element of the modal

  const returnBrandsDisplayValue = () => {
    let displayValue = allBrandsLabel ?? 'All Brands';
    if (userSelectedBrands?.length === 0 || userSelectedBrands?.length === totalBrandCount) {
      displayValue = allBrandsLabel ?? 'All Brands';
    } else {
      const brandsDisplay =
        userSelectedBrands?.length > 1
          ? brandsSelectedLabel ?? 'Brands Selected'
          : brandSelectedLabel ?? 'Brand Selected';
      displayValue = brandsDisplay?.includes('{x}')
        ? brandsDisplay.replace('{x}', userSelectedBrands?.length?.toString())
        : userSelectedBrands?.length?.toString() + ' ' + brandsDisplay;
    }
    return displayValue;
  };

  const inputRef = useRef<HTMLInputElement>(null); // ref to point parent div of input text field

  // function to close dropdown when focus leaves both input and dropdown
  const handleBlur = (event: any) => {
    const relatedTarget = event.relatedTarget;
    if (
      relatedTarget &&
      !inputRef?.current?.contains(relatedTarget) &&
      !brandsCompRef.current?.contains(relatedTarget)
    ) {
      setOpenPopupState(false);
    }
  };

  return (
    /**
     * search secondry block or bottom block of search form
     */
    <div
      className={clsx(
        'document_search_form',
        'standard',
        isTabbedSearchForm ? 'mb-0 mb-lg-5 pb-md-4' : '',
        'pb-2',
        'pb-lg-0',
        'd-flex mr-n2 ml-n2 mr-lg-0 ml-lg-0 flex-wrap flex-lg-nowrap',
        isTabbedSearchForm !== true ? 'py-0 py-lg-3 px-0 px-lg-4 mb-3' : 'py-0 px-0',
        isDesktopView || (!isDesktopView && isTabbedSearchForm !== true) ? 'search-form-secondary-wrapper' : '',
        isTabbedSearchForm !== true ? (!showSecondaryForm ? 'd-lg-none d-flex' : '') : '',
        isTabbedSearchForm && 'd-md-flex',
        isTabbedSearchForm ? 'search-form-secondary-wrapper-tabbed justify-content-between' : '',
        disableSpecialRates
          ? 'col-lg-4 col-md-12'
          : hideDestination === true && hideBrandsField === true && isDesktopView
          ? 'col-lg-6 available-secondary-column'
          : ''
      )}
    >
      {isTabbedSearchForm !== true && hideDestination !== true ? (
        <div
          className={clsx(
            'standard',
            'col-lg-auto',
            'col-12',
            isTabbedSearchForm !== true ? 'mb-5' : 'mb-3',
            'mb-lg-0',
            'custom_click_track'
          )}
        >
          {<RoomsAndGuests isDesktopView={isDesktopView} isAvailabilityScenario={isAvailabilityScenario} />}
        </div>
      ) : (
        ''
      )}
      {!isTabbedSearchForm && !disableSpecialRates ? (
        <div
          className={clsx(
            'col-lg-auto mb-5',
            'col-12',
            'mb-lg-0',
            'custom_click_track',
            'standard',
            hideDestination === true && hideBrandsField === true && isDesktopView ? 'col-lg-6' : ''
          )}
        >
          <SpecialRates
            isDesktopView={isDesktopView}
            isAvailabilityScenario={isAvailabilityScenario}
            errorMessage={returnApplicableAlert(corporateCodeFormatLengthInvalidError, alertsListArray)}
          />
        </div>
      ) : !disableSpecialRates ? (
        <div
          className={clsx(
            'd-block d-md-none d-lg-block',
            'col-lg-auto mb-3 col-md-6',
            'col-12',
            'mb-lg-0',
            'custom_click_track',
            'standard',
            isTabbedSearchForm === true ? 'ml-0 ml-lg-n2' : '',
            isTabbedSearchForm === true ? 'flex-lg-grow-1 flex-shrink-0 flex-grow-0 flex-basis' : '',
            isDesktopView && isTabbedSearchForm !== true ? 'ml-2' : ''
          )}
        >
          <SpecialRates
            isDesktopView={isDesktopView}
            hideDestination={hideDestination}
            hideBrandsField={hideBrandsField}
            isADFPage={hideDestination === true && hideBrandsField === true}
            isAvailabilityScenario={isAvailabilityScenario}
            errorMessage={returnApplicableAlert(corporateCodeFormatLengthInvalidError, alertsListArray)}
          />
        </div>
      ) : (
        ' '
      )}

      {isTabbedSearchForm && hideBrandsField !== true && (
        <div
          ref={inputRef}
          {...{
            custom_click_track_value: `${searchFormClickTrackingLoc} | Brand Filter Selected |internal`,
          }}
          className={clsx(
            'standard',
            'col-lg-auto mb-3',
            'col-12',
            'mb-lg-0',
            'col-md-6',
            'custom_click_track',
            isTabbedSearchForm ? 'flex-lg-grow-1 flex-shrink-0 flex-grow-0 flex-basis adv-brand-filter' : '',
            'pl-2'
          )}
        >
          <InputTextField
            className={'m-input-text-field brands-filter-input-container'} // these 2 class names are mandatory , please do not remove
            iconOnClick={() => {
              setOpenPopupState(!openPopup);
            }}
            onBlur={handleBlur}
            handleKeyDown={() => {
              setOpenPopupState(!openPopup);
            }}
            label={brandsLabel ?? 'Brands'}
            iconAriaLabel={openPopup ? 'close dropdown' : 'open dropdown'}
            inputValue={returnBrandsDisplayValue()}
            showIcon={true}
            iconClass={openPopup ? 'icon-arrow-up' : 'icon-arrow-down'}
            inputTextFieldClassName="brand-field t-font-weight-m"
            getInputProps={() => ({
              readOnly: true,
            })}
            isClickEnabled={true}
            variation={'default'}
            withinModal={true}
            showUnderline={true}
            trailingIconProps={{
              className: clsx('trailing-element', 'brand-filters-arrow-icon'), // these 2 class names are mandatory , please do not remove
              onKeyDown: (e: KeyboardEvent) => {
                if (e.key === 'Enter' || e.code === '13') {
                  setOpenPopupState(!openPopup);
                }
              },
            }}
          ></InputTextField>
          <div ref={brandsCompRef}>
            {openPopup && (
              <DropDownModal
                role="dialog"
                labelledBy="brands-dropdown"
                shouldAutoFocusOnPopup={true}
                isAutoScrollEnabled={true}
                parentElementTopValue={searchFormTopPosition?.value}
                applyDefaultHeight={true}
                mobileTakeOver={isDesktopView ? false : true}
                mobileModalHeading={brandsLabel ?? 'Brands'}
                show={openPopup}
                className={`dropdown-container adv-search-brands-dropdown ${isDesktopView ? 'hide-dropdown' : ''}`}
                childrenClassName={'search-field-dropdown adv-search-brand-field-dropdown'}
                mobileHeaderEnabled={isDesktopView ? false : true}
                mobileFooterEnabled={true}
                dropdownModalOpenState={openPopup}
                scrollDownModalCloseState={true}
                setDropdownModalOpenState={setOpenPopupState}
                dropdownModalOnCLoseFunc={() => {
                  setOpenPopupState(false);
                }}
                clearClickHandler={onClearClickHandler}
                handleBlur={true}
                clearButtonLabel={brandsResetCta ?? 'Reset'}
                applyLabel={done ?? 'Done'}
                applyBtnClickHandler={onClickHandler}
                desktopDropdown={isDesktopView}
                dropDownSpecificFlag={true}
                callhandleBlur={handleBlur}
                clickTrackingLoc={searchFormClickTrackingLoc}
                customLastElementRef={buttonRef}
              >
                <BrandFilter
                  title={''}
                  showSeparator={false}
                  brandCategories={renameBrandsKey(brandCategories)}
                  defaultSelected={userSelectedBrands}
                  onChange={onBrandChangeHandler}
                  isHotelBrandSelectedFlag={true}
                  isTabbedSearchForm={isTabbedSearchForm}
                  shouldSelectionsBeReset={openPopup}
                  clickTrackingLoc={searchFormClickTrackingLoc}
                />
              </DropDownModal>
            )}
          </div>
        </div>
      )}
      {isTabbedSearchForm ? (
        <SearchFormSubmitButton
          customClass={`${
            disableSpecialRates ? 'col-md-12 d-none d-md-block d-lg-none' : 'd-none d-md-block d-lg-none'
          }`}
          findHotelLabel={submitCTA}
          submitAction={submitAction}
          alertsListArray={alertsListArray}
          sessionData={sessionData}
          isAvailabilityScenario={isAvailabilityScenario}
        />
      ) : (
        ''
      )}
      <div
        className={clsx(
          isTabbedSearchForm ? 'col-lg-auto align-items-center use-points-wrapper' : 'col-lg-auto ',
          isTabbedSearchForm && !isDesktopView ? '' : 'mb-3',
          isTabbedSearchForm ? 'pt-0 pt-lg-3' : '',
          'justify-content-lg-center justify-content-start',
          'col-12',
          'mb-lg-0',
          'd-flex',
          'standard',
          hideDestination === true && hideBrandsField === true && isDesktopView ? 'availibility-reward-points' : '',
          !isTabbedSearchForm && !isMobileView ? 'mb-5' : ''
        )}
      >
        {enableRedeemPoints ? <UsePoints /> : ''}
      </div>

      {isTabbedSearchForm ? (
        <SearchFormSubmitButton
          customClass={clsx(
            'd-none d-lg-block',
            disableSpecialRates
              ? 'col-lg-12 col-md-12'
              : hideDestination === true && hideBrandsField === true && isDesktopView
              ? 'col-lg-6'
              : ''
          )}
          findHotelLabel={submitCTA}
          submitAction={submitAction}
          alertsListArray={alertsListArray}
          lastElementRef={buttonRef}
          sessionData={sessionData}
          isAvailabilityScenario={isAvailabilityScenario}
        />
      ) : (
        ''
      )}
    </div>
  );
};
