/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/ban-ts-comment */
/* eslint-disable  @typescript-eslint/no-var-requires */
// @ts-ignore
import { getTargetOffers } from './targetClientOffers';
import createMappingOffersModelJSON from './mappingTemplate';

import { dcaDynamicContent } from '@marriott/mi-merch-graphql';
import { getNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { inspect } from 'util';
import { logger } from './../logger';
import { generateRandomNumber } from '@marriott/shared/mi-helper-utils';

const transformJSON = require('json-map-transform');

export const fetchImageAsset = async (assetId: string) => {
  const { log } = logger({})('fetchImageAsset');

  const MiApolloClient = getNextMiApolloClient([], '');
  try {
    const { data } = await MiApolloClient.query({
      query: dcaDynamicContent,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: { assetId },
      context: {
        headers: {},
      },
    });

    log.debug(`Dac call success, ${JSON.stringify(data)}`);

    return (
      //If the dac returns null, convert the dac id to path and pass it back
      data?.digitalAsset?.imageByAssetId || {
        imageSrc: atob(assetId),
      }
    );
  } catch (error) {
    log.error(`Dac call error, ${JSON.stringify(error)}`);
    //If the dac api fails, convert the dac id to path and pass it back
    return {
      imageSrc: atob(assetId),
    };
  }
};

const imageDomain = useClientEnvVarsStore.getState().envVarsObject['DAC_IMG_DOMAIN'] || 'https://cache.marriott.com';

export interface TrackingPropsInterface {
  trackingContentPosition?: string;
  trackingOfferType?: string;
  atCCeVar48?: string;
  trackingDescription?: string;
  trackingTag?: string;
  isCoBrand?: boolean;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  impressionCount?: boolean;
  impressionEventType?: string;
  merchandisingCategory?: string;
  additionalTrackingVariables?: string;
  location?: string;
  payloadType?: string;
  compName?: string;
  enableScrollingBehavior?: boolean;
  isAuth?: boolean;
  clickTrackValSeparator?: string;
  cell?: string;
  spid?: string;
  rpcCode?: string;
  isDCAEnabled?: boolean;
  offerCode?: string;
}

export function generateRandomString() {
  const characters = '0123456789ABCDEF';
  let randomString = '';
  for (let i = 0; i < 32; i++) {
    randomString += characters.charAt(Math.floor(generateRandomNumber() * characters.length));
    if (i === 7 || i === 11 || i === 15 || i === 19) randomString += '-';
  }
  return randomString;
}

const dynamicMediaTransformation = (compModel: any, mBopDynamicMedia: any) => {
  const renditions = mBopDynamicMedia?.imageUrls // Check if mBopDynamicMedia.renditions is defined
    ? compModel.renditions.map((rend: any) => {
        // Map over each rendition in compModel.renditions
        const renditionUrl = rend?.smartCropType
          ? mBopDynamicMedia?.imageUrls[rend?.smartCropType]
          : mBopDynamicMedia.imageSrc; // Get the URL for this rendition based on its smartCropType
        const params = rend.dynamic ? `?wid=${rend.width * 2}&hei=${rend.height * 2} 2x` : ''; // Construct parameters for dynamic resizing if needed
        return {
          ...rend, // Spread the properties of the original rendition
          renditionPath: imageDomain + renditionUrl + params, // Combine image domain, rendition URL, and params to get the rendition path
          damPath: mBopDynamicMedia?.imageSrc, // Assign the DAM path from mBopDynamicMedia
        };
      })
    : []; // If mBopDynamicMedia.renditions is not defined, return an empty array

  const aemDynamicJSON = {
    ...compModel,
    altText: mBopDynamicMedia?.alternateDescription,
    damPath: mBopDynamicMedia?.imageSrc,
    assetPath: imageDomain + mBopDynamicMedia?.imageSrc,
    renditions: renditions,
  };
  return aemDynamicJSON;
};

export const transformTargetModel = (componentModel: any, mBopResp: any) => {
  try {
    const updatedComponentModel = { ...componentModel }; // Create a copy to avoid modifying the original object directly
    for (const [key, value] of Object.entries(mBopResp)) {
      if (key === 'image') {
        // Check if 'image' key exists in componentModel and perform transformation
        updatedComponentModel[key] = componentModel[key]
          ? dynamicMediaTransformation(componentModel[key], value)
          : value;
      } else if (key === 'logo') {
        // Assert the type of 'value' to a specific type if you know its structure
        const logoValue = value as { imageSrc?: string; alternateDescription?: string };
        // Transform the 'logo' property and update the imageSrc if it exists
        if (logoValue && logoValue.imageSrc) {
          const updatedLogo = {
            ...logoValue,
            imageSrc: imageDomain + logoValue.imageSrc,
            altText: logoValue.alternateDescription,
          };
          updatedComponentModel[key] = updatedLogo;
        } else {
          // Handle case where 'logo' object or 'imageSrc' property is undefined
          console.error("'logo' object or 'imageSrc' property is undefined");
        }
      } else {
        // Assign other properties directly
        updatedComponentModel[key] = value;
      }
    }
    // Set isTargetContentLoaded property after transformation is complete
    updatedComponentModel.isTargetContentLoaded = true;

    return updatedComponentModel;
  } catch (error) {
    // Log the error
    console.error('Error occurred during transformation====', error);
    // Return the original componentModel in case of error
    return componentModel;
  }
};

export async function dynamicContentDataSource(req: any) {
  const locale: string = req.locale;
  const { log } = logger({})('dynamicContentDataSource');
  try {
    const { response, responseTokens } = await getTargetOffers(req.targetPayload);
    const jsonResp = response?.execute?.mboxes[0]?.options[0] || {};
    let contentData;
    if (jsonResp.type === 'json') {
      contentData = jsonResp.content;
    } else {
      contentData = JSON.parse(jsonResp.content);
    }

    log.debug(`Target call success', ${JSON.stringify(contentData)}`);

    const { content, metadata } = contentData?.offer || {};
    content.metadata = metadata;
    content.responseTokens = responseTokens;
    let logoAsset, imageAsset;

    if (content?.logo?.assetId) {
      logoAsset = await fetchImageAsset(content?.logo?.assetId);
    }
    if (content?.image?.assetId) {
      imageAsset = await fetchImageAsset(content?.image?.assetId);
    }

    if (content?.logo?.assetId && logoAsset) {
      content.logo =
        (useClientEnvVarsStore.getState().envVarsObject['DAC_IMG_DOMAIN'] || 'https://cache.marriott.com') +
        logoAsset.imageSrc;
      content.logoAltText = logoAsset?.alternateDescription;
    }

    if (content?.image?.assetId && imageAsset) {
      content.image = {
        ...content.image,
        renditions: imageAsset.imageUrls,
        altText: imageAsset.alternateDescription,
        imageSrc: imageAsset.imageSrc,
      };
    }

    log.debug(`Mbop Content Logo', ${content.logo}`);
    log.debug(`Mbop Content Image', ${content.image}`);

    const mappingOffersModelJSON = createMappingOffersModelJSON(locale);

    log.debug(`mappingOffersModelJSON', ${inspect(mappingOffersModelJSON)}`);

    return transformJSON(content, mappingOffersModelJSON);
  } catch (error) {
    throw new Error(`Error fetching target data: ${error}`);
  }
}

export async function fetchTargetSegment(req: any) {
  try {
    const { response } = await getTargetOffers(req.targetReqPayload);

    return response;
  } catch (error) {
    throw new Error('Failed to fetch target data');
  }
}

export function atGetSPID(arr: Array<string>) {
  let spid = '';

  window?.mvpOffers?.specialMessage?.split(',').forEach(function (mvpSpid: string) {
    arr.forEach(function (metaDataSpid: string) {
      if (mvpSpid === metaDataSpid) {
        spid = mvpSpid;
      }
    });
  });

  return spid;
}

export const getSPID = (targetData: Record<string, any>) => {
  const preQual = targetData?.['metaData']?.prequal || '';
  const prequalSpids = targetData?.['metaData']?.spid || '';
  if (String(preQual) === 'true') {
    const spidList = prequalSpids ? prequalSpids?.split(',') : [];
    const preQualSPID = atGetSPID(spidList);
    return preQualSPID;
  }
  return prequalSpids;
};

export const getEVAR48 = (targetData: Record<string, any>): string => {
  const countryCode = targetData?.['metaData']?.countryCode || '';
  const issuerCode = targetData?.['metaData']?.issuerCode || '';
  const rpcCode = targetData?.['metaData']?.rpcCode || '';
  const offerCode = targetData?.['metaData']?.offerCode || '';
  const cell = targetData?.['metaData']?.cell || '';
  const preQual = targetData?.['metaData']?.prequal;
  let evar48: string = `CCOFFER-${countryCode}-${issuerCode}-dca-${rpcCode}-${offerCode}`;
  if (
    (targetData?.['metaData']?.rpcCode === '0513' || targetData?.['metaData']?.rpcCode === '0532') &&
    targetData?.['metaData']?.offerCode.includes('ACQ')
  ) {
    evar48 = evar48 + `-${cell}`;
  }
  const spid = getSPID(targetData);
  if (spid) {
    evar48 = evar48 + '-' + spid;
  }
  if (String(preQual) === 'true') {
    evar48 = evar48 + '-PQO';
  }
  return evar48;
};
