import { FC, useState } from 'react';
import clsx from 'clsx';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { Accordion, Button, InlineMessages, InlineMessagesType } from '@marriott/mi-ui-library';
import { FileUpload } from '../FileUpload';
import { TextAreaFormField } from '../TextAreaFormField';
import { convertFileSize, uploadFile, deleteFile } from '../../utils';
import { RFP_UPLOAD_MAX_SIZE, RFP_UPLOAD_ALLOWED_FILE_TYPES, RFP_UPLOAD_MAX_FILES } from '../../constants';
import { useRfpStore } from '../../store';
import { FileErrorType } from '../../molecules/FileUpload/FileUpload.types';
import { StyledAttachments } from './Attachments.styles';
import { AttachmentsFormData, AttachmentsProps, SelectedFile, UploadErrorResponse } from './Attachments.types';

export const Attachments: FC<AttachmentsProps> = props => {
  const { labels, control } = props;

  const { eventProfileData } = useRfpStore();
  const initialTotalFileUploadSize =
    eventProfileData?.files?.reduce((total, file) => total + file.fileDetail.size, 0) || 0;

  const [totalFileUploadSize, setTotalFileUploadSize] = useState(initialTotalFileUploadSize);
  const [uploadFileLoader, setUploadFileLoader] = useState(false);
  const [deleteFileLoader, setDeleteFileLoader] = useState(false);
  const [fileError, setFileError] = useState<FileErrorType>(FileErrorType.NO_ERROR);

  const { fields, append, remove } = useFieldArray({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: control as Control<AttachmentsFormData, any>,
    name: 'files',
  });

  const handleFileUpload = async (file: File) => {
    setUploadFileLoader(true);
    try {
      const { data } = await uploadFile(file, 'rfp');
      append({ key: data.data?.[0].documentId || '', fileDetail: file, description: '' });
      setTotalFileUploadSize(prevTotalFileUploadSize => prevTotalFileUploadSize + file.size);
    } catch (error) {
      const { response } = error as UploadErrorResponse;
      const { status, data } = response;
      const errorCode = data?.errors?.[0].code;
      if (status === 422 && errorCode === '602') {
        setFileError(FileErrorType.SIZE_ERROR);
      } else if (status === 422 && errorCode === '609') {
        setFileError(FileErrorType.FORMAT_ERROR);
      } else if (status === 422 && errorCode === '612') {
        setFileError(FileErrorType.UPLOAD_VIRUS_ERROR);
      } else {
        setFileError(FileErrorType.UPLOAD_FILE_ERROR);
      }
    } finally {
      setUploadFileLoader(false);
    }
  };

  const handleFileDeletion = async (file: SelectedFile, index: number, event: React.MouseEvent) => {
    event.stopPropagation();
    setFileError(FileErrorType.NO_ERROR);
    setDeleteFileLoader(true);
    try {
      const { data } = await deleteFile(file.key, 'rfp');
      if (data.data.fileDetails?.[0].deleted) {
        remove(index);
        setTotalFileUploadSize(prevTotalFileUploadSize => prevTotalFileUploadSize - file.fileDetail.size);
      } else {
        setFileError(FileErrorType.DELETE_FILE_ERROR);
      }
    } catch (error) {
      setFileError(FileErrorType.DELETE_FILE_ERROR);
    } finally {
      setDeleteFileLoader(false);
    }
  };

  return (
    <StyledAttachments data-component-name="m-groups-Attachments" data-testid="groups-Attachments">
      {fileError !== FileErrorType.NO_ERROR ? (
        <div className="mb-4">
          <InlineMessages
            type={InlineMessagesType.Error}
            title={
              fileError === FileErrorType.SIZE_ERROR
                ? labels.fileSizeError
                : fileError === FileErrorType.FORMAT_ERROR
                ? labels.fileFormatError
                : fileError === FileErrorType.UPLOAD_VIRUS_ERROR
                ? labels.virusApiError
                : fileError === FileErrorType.UPLOAD_FILE_ERROR
                ? labels.apiError
                : labels.removeApiError
            }
            severity="1"
          />
        </div>
      ) : null}
      <div>
        <p className="m-0 t-font-s">{labels.attachmentsHelperText}</p>
        <p className="m-0 t-font-s">{labels.acceptedFileFormat}</p>
        <p className="mt-3 t-font-s">{labels.maxFileSize}</p>
      </div>
      <div className="my-4 add-attachment">
        <FileUpload
          ctaLabel={labels.addAttachment}
          ctaClass={clsx('add-attachment-cta', uploadFileLoader && 'skeleton-loader')}
          disabled={uploadFileLoader || !(fields.length < RFP_UPLOAD_MAX_FILES)}
          allowedFileTypes={RFP_UPLOAD_ALLOWED_FILE_TYPES}
          maxSize={RFP_UPLOAD_MAX_SIZE}
          onFileSelected={handleFileUpload}
          onError={(errorType: FileErrorType) => setFileError(errorType)}
        />
        <div className="attachment-size">{`${labels.size} ${convertFileSize(totalFileUploadSize)}`}</div>
      </div>
      {fields.length ? (
        <div className="attachment-list">
          <div className="mr-3 mb-3 attachment-list-header">
            <div className="t-font-s">{labels.fileName}</div>
            <div className="t-font-s">{labels.fileSize}</div>
          </div>
          {fields.map((field, index) => (
            <div key={field.id} className="py-2 attachment-list-body">
              <Accordion
                id={`file${index}`}
                isOpen={false}
                enableFullWidthHeader={true}
                disableAccResize={true}
                headerChildren={
                  <div className="attachment-list-item">
                    <div className="file-name">{field.fileDetail.name}</div>
                    <div className="file-size">
                      <div className="t-font-s">{convertFileSize(field.fileDetail.size)}</div>
                      <Button
                        className="icon-delete delete-file"
                        ariaLabel={labels.removeAriaLabel}
                        isDisabled={deleteFileLoader}
                        testId={`deleteFileCta${index}`}
                        callback={event => handleFileDeletion(field, index, event as React.MouseEvent)}
                      />
                    </div>
                  </div>
                }
                headerAriaLabel={`${labels.fileName}:${field.fileDetail.name}, ${labels.fileSize}:${convertFileSize(
                  field.fileDetail.size
                )}`}
              >
                <div>
                  <Controller
                    name={`files.${index}.description`}
                    control={control}
                    render={({ field }) => (
                      <TextAreaFormField
                        {...field}
                        placeholder={labels.fileDetailsPlaceholder}
                        maxLength={500}
                        onChange={event => field.onChange(event)}
                      />
                    )}
                  />
                </div>
              </Accordion>
            </div>
          ))}
        </div>
      ) : null}
    </StyledAttachments>
  );
};
