/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef, useContext } from 'react';
import { StyledSearchAllFilters } from './SearchAllFilters.styles';
import clsx from 'clsx';
import {
  Button,
  CheckboxControlsList,
  ToggleControlsList,
  RadiobuttonControlsList,
  Modal,
} from '@marriott/mi-ui-library';
/* eslint-disable @nx/enforce-module-boundaries */
import { BrandFilter } from '@marriott/mi-shop-components';
import { SearchButtonPill } from '../SearchButtonPill/index';
import {
  RnBFILTERS,
  TRACKING_CONSTANT,
  otsUrlTypeTerm,
  allFilters,
  cuisineFilter,
  locationFilter,
  otsUrlTypeValue,
  golfStateFilterType,
  golfActivitiesDimension,
  golfActivitiesType,
} from '../../../modules/utils/constants';
import searchFilterStore from '../../../modules/store/store';
import { searchResultsState, useStore } from '../../../modules/store/searchResultsStore';
import { terms } from './SearchAllFilters.types';
import {
  getCategorizedBrands,
  getDirection,
  getDistanceUnit,
  getProcessedFilterData,
  getTrackingProperties,
  scrollSection,
  trackImpression,
  updateDistanceFilterUnits,
  useGetBreakpoint,
} from '../../../modules/utils/helpers';
import {
  campaignOffersDetailsFacets,
  golfCoursePropertiesDetailsFacets,
  searchRestaurantsByGeolocationFacets,
  searchRestaurantsByLocationFacets,
  searchRestaurantsByOpenTextFacets,
} from '@marriott/mi-rnb-graphql';
import { useApolloClient } from '@apollo/client';
import { rnbErrorState, rnbErrorStore } from 'libs/mi-rnb-components/src/modules/store/rnbErrorStore';
import {
  autoCompleteState,
  useAutoCompleteStore,
} from 'libs/mi-rnb-components/src/modules/store/autoCompleteDetailsStore';
import { PAGES } from '../../../modules/utils/constants';
import { campaignDetailsStore } from '../../../modules/store/campaignDetailsStore';
import { PageParamsContext } from '@marriott/mi-rnb-components/context';

export const SearchAllFilters = (props: any) => {
  const filterData = useStore((state: any) => state.facetsData);
  const setErrorState = rnbErrorStore((state: rnbErrorState) => state.setErrorState);
  let filterUpdateData = useStore((state: searchResultsState) => state.facetsData);
  const [currFilterData, setCurrFilterData] = useState(filterData);
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const [filterOrderList, setFilterOrderList] = useState(props?.filtersOrderList);
  const [showSelectedPills, setShowSelectedPills] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const setIsLoaderVisible = useStore((state: any) => state.setIsLoaderVisible);
  const queryParam = useStore((state: searchResultsState) => state.queryParam);
  const setQueryParam = useStore((state: any) => state.setQueryParam);
  const setPaginationChange = useStore((state: any) => state.setPaginationChange);
  const setPageNumber = useStore((state: any) => state.setPageNumber);
  const facetsData = useStore((state: any) => state.facetsData);
  const hours = searchFilterStore((state: any) => state.hours);
  const distance = searchFilterStore((state: any) => state.distance);
  const cuisine = searchFilterStore((state: any) => state.cuisine);
  const seating = searchFilterStore((state: any) => state.seating);
  const bonvoyMembers = searchFilterStore((state: any) => state.bonvoyMembers);
  const brands = searchFilterStore((state: any) => state.brands);
  const venueType = searchFilterStore(state => state.venueType);
  const dietaryOptions = searchFilterStore(state => state.dietaryOptions);
  const experiences = searchFilterStore(state => state.experiences);
  const city = searchFilterStore(state => state.city);
  const stateProvinceDescription = searchFilterStore(state => state.stateProvinceDescription);
  const statesData = searchFilterStore(state => state.statesData);
  const countryDescription = searchFilterStore(state => state.countryDescription);
  const countryData = searchFilterStore(state => state.country);
  const hourSelection = searchFilterStore((state: any) => state.hourSelection);
  const cuisineSelection = searchFilterStore((state: any) => state.cuisineSelection);
  const distanceSelection = searchFilterStore((state: any) => state.distanceSelection);
  const seatingSelection = searchFilterStore((state: any) => state.seatingSelection);
  const bonvoySelection = searchFilterStore((state: any) => state.bonvoySelection);
  const brandSelection = searchFilterStore((state: any) => state.brandSelection);
  const venueTypeSelection = searchFilterStore(state => state.venueTypeSelection);
  const dietaryOptionsSelection = searchFilterStore(state => state.dietaryOptionsSelection);
  const experiencesSelection = searchFilterStore(state => state.experiencesSelection);
  const citySelection = searchFilterStore(state => state.citySelection);
  const stateProvinceDescSelection = searchFilterStore(state => state.stateProvinceDescSelection);
  const statesSelection = searchFilterStore(state => state.statesSelection);
  const countryDescSelection = searchFilterStore(state => state.countryDescSelection);
  const countrySelection = searchFilterStore(state => state.countrySelection);
  const setFilter = searchFilterStore((state: any) => state.setFilter);
  const setFilterRealTimeTerms = searchFilterStore((state: any) => state.setFilterRealTimeTerms);
  const setFilterRealTimeRanges = searchFilterStore((state: any) => state.setFilterRealTimeRanges);
  const appliedFilter = searchFilterStore((state: any) => state.appliedFilter);
  const realTimeFilterTerms = searchFilterStore((state: any) => state.realTimeFilter.terms);
  const realTimeFilterRanges = searchFilterStore((state: any) => state.realTimeFilter.ranges);
  const campaignId = campaignDetailsStore(state => state.campaignId);
  const [selectedFilter, setSelectedFilter] = useState<terms[]>(appliedFilter || []);
  const selectedFilters: terms[] = [];
  let urlparams = '';
  const filterButtonType = searchFilterStore(state => state.filterButtonType);
  const setFilterButtonType = searchFilterStore(state => state.setFilterButtonType);
  const [isMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const scrollableSectionRef = useRef<HTMLDivElement | null>(null);
  const selectedPlaceDetailsData = useAutoCompleteStore((state: autoCompleteState) => state.selectedPlaceDetailsData);
  let latitude: string | number | null = null;
  let longitude: string | number | null = null;
  let state: string | null = null;
  let country: string | null = null;
  let isOpenTextSearch: boolean | null = null;
  let userInputOpenSearchTerm: string | null = null;
  const isNewFacetsLoading = searchFilterStore(state => state.isNewFacetsLoading);
  const setIsNewFacetsLoading = searchFilterStore(state => state.setIsNewFacetsLoading);
  const isDirectionRTL = getDirection() === 'rtl';
  const { SEARCH_RESULTS_PAGE, CAMPAIGN_PAGE, GOLF_LOCATION_PAGE } = PAGES;
  const { currentLocale } = useContext(PageParamsContext);

  getCategorizedBrands(currFilterData, props?.categorizedBrandsArray);

  const updateStoreData = (selectedVal: any, updatedFilterValues: any, filterName: any, storeFilterUpdater: any) => {
    const filterData = getProcessedFilterData(facetsData, filterName, props, currentLocale ? currentLocale : 'en-us');
    if (filterName === RnBFILTERS.states) {
      filterData?.forEach((item: any) => {
        if (selectedVal?.includes(item.code)) {
          updatedFilterValues.push(item);
        }
      });
      storeFilterUpdater(updatedFilterValues);
    } else {
      filterData?.forEach((item: any) => {
        if (selectedVal?.includes(item.label)) {
          updatedFilterValues.push(item);
        }
      });
      storeFilterUpdater(updatedFilterValues);
    }
  };

  const searchResultDetailsClient = useApolloClient();
  async function getSearchResultsDetails(inputValues: any, locationQueryType: any) {
    // setData([], true, false, []);
    setIsNewFacetsLoading(true);
    let searchResults,
      searchResultsError = false;
    try {
      const { data } = await searchResultDetailsClient.query({
        query: locationQueryType,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        ...inputValues,
      });
      if (data) {
        searchResults = data;
        setIsNewFacetsLoading(false);
      }
    } catch (error) {
      setIsNewFacetsLoading(false);
      if (searchResults) searchResultsError = false;
      else searchResultsError = true;
      setIsLoaderVisible(false);
    } finally {
      setIsNewFacetsLoading(false);
      if (
        !searchResultsError &&
        (searchResults?.searchRestaurantsByGeolocation?.facets?.length >= 0 ||
          searchResults?.searchRestaurantsByLocation?.facets?.length >= 0 ||
          searchResults?.searchRestaurantsByOpenText?.facets?.length >= 0)
      ) {
        filterUpdateData = searchResults?.searchRestaurantsByGeolocation
          ? searchResults?.searchRestaurantsByGeolocation
          : searchResults?.searchRestaurantsByOpenText
          ? searchResults?.searchRestaurantsByOpenText
          : searchResults?.searchRestaurantsByLocation;
      } else setErrorState(0, '');
      if (filterUpdateData?.facets?.length > 0) {
        setCurrFilterData(filterUpdateData?.facets);
      }
      setIsLoaderVisible(false);
    }
  }

  const campaignResultDetailsClient = useApolloClient();
  async function getCampaignResultsDetails(inputValues: any) {
    setIsNewFacetsLoading(true);
    let campaignResults,
      campaignResultsError = false;
    try {
      const { data } = await campaignResultDetailsClient.query({
        query: campaignOffersDetailsFacets,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        ...inputValues,
      });
      if (data) {
        campaignResults = data;
        setIsNewFacetsLoading(false);
      }
    } catch (error) {
      setIsNewFacetsLoading(false);
      if (campaignResults) campaignResultsError = false;
      else campaignResultsError = true;
      setIsLoaderVisible(false);
    } finally {
      setIsNewFacetsLoading(false);
      if (
        !campaignResultsError &&
        campaignResults?.diningOfferCampaignDetails &&
        campaignResults?.diningOfferCampaignDetails?.OfferDetail?.facets?.length > 0
      ) {
        const facets = campaignResults?.diningOfferCampaignDetails?.OfferDetail?.facets?.map((facet: any) => {
          return {
            ...facet,
            buckets: facet?.buckets?.filter(
              (bucket: any) => bucket?.label !== '' && bucket?.label !== null && bucket?.label !== undefined
            ),
          };
        });
        setCurrFilterData(facets);
      }
      setIsLoaderVisible(false);
    }
  }

  const golfLocationDetailsClient = useApolloClient();
  async function getGolfLocationDetails(inputValues: any) {
    setIsNewFacetsLoading(true);
    let golfLocations,
      golfLocationsError = false;
    try {
      const { data } = await golfLocationDetailsClient.query({
        query: golfCoursePropertiesDetailsFacets,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        ...inputValues,
      });
      if (data) {
        golfLocations = data;
        setIsNewFacetsLoading(false);
      }
    } catch (error) {
      setIsNewFacetsLoading(false);
      if (golfLocations) golfLocationsError = false;
      else golfLocationsError = true;
      setIsLoaderVisible(false);
    } finally {
      setIsNewFacetsLoading(false);
      if (
        !golfLocationsError &&
        golfLocations?.searchPropertiesByDestination &&
        golfLocations?.searchPropertiesByDestination?.facets?.length > 0
      ) {
        const facets = golfLocations?.searchPropertiesByDestination?.facets?.map((facet: any) => {
          return {
            ...facet,
            buckets: facet?.buckets?.filter(
              (bucket: any) => bucket?.label !== '' && bucket?.label !== null && bucket?.label !== undefined
            ),
          };
        });
        setCurrFilterData(facets);
      }
      setIsLoaderVisible(false);
    }
  }

  const getNewFacets = (filterType: string, selectedFilterValue: any) => {
    if (props?.currentPage === SEARCH_RESULTS_PAGE) {
      const query = new URLSearchParams(queryParam);
      isOpenTextSearch = query.has(otsUrlTypeTerm) && query?.get(otsUrlTypeTerm) === otsUrlTypeValue;
      if (selectedPlaceDetailsData) {
        latitude = selectedPlaceDetailsData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.latitude;
        longitude = selectedPlaceDetailsData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.longitude;
        state = selectedPlaceDetailsData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.state;
        country = selectedPlaceDetailsData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.countryName;
      } else if (!selectedPlaceDetailsData && query.get('lat') && query.get('long')) {
        const lat: string | number = parseFloat(query?.get('lat')?.toString() ?? '0');
        const long: string | number = parseFloat(query?.get('long')?.toString() ?? '0');
        latitude = lat;
        longitude = long;
      } else if (
        !selectedPlaceDetailsData &&
        !query.get('lat') &&
        !query.get('long') &&
        query.get('term2') &&
        query.get('term3')
      ) {
        state = query?.get('term2');
        country = query?.get('term3');
      } else if (
        !selectedPlaceDetailsData &&
        !query.get('lat') &&
        !query.get('long') &&
        !query.get('term2') &&
        query.get('term3')
      ) {
        country = query?.get('term3');
      } else if (isOpenTextSearch) {
        userInputOpenSearchTerm = query?.get('term');
      }

      let selectedDimensions: string[] = [];
      if (filterType === 'distance') {
        const distanceFacetsVal: {
          type: string;
          endpoints: string[];
          dimensions: string[];
        } = { type: '', endpoints: [], dimensions: ['1'] };
        distanceFacetsVal.type = filterType;
        distanceFacetsVal['endpoints'] = [
          selectedFilterValue[0].label.split(' ')[0],
          selectedFilterValue[0].label.split(' ')[2],
        ];
        const existingIndex = realTimeFilterRanges.findIndex(
          (item: { type: string }) => item.type === distanceFacetsVal.type
        );

        if (existingIndex === -1) {
          realTimeFilterRanges.push(distanceFacetsVal);
        } else {
          realTimeFilterRanges[existingIndex] = distanceFacetsVal;
        }
        setFilterRealTimeRanges(realTimeFilterRanges);
      } else if (filterType === 'brandId') {
        const facetsVal = { type: '', dimensions: [] };
        facetsVal.type = filterType;
        const selectedBrands = selectedFilterValue.map((brand: any) => brand.brandTagId);
        facetsVal.dimensions = selectedBrands;
        const existingIndex = realTimeFilterTerms.findIndex((item: { type: string }) => item.type === filterType);
        if (existingIndex === -1) {
          realTimeFilterTerms.push(facetsVal);
        } else {
          if (facetsVal.dimensions.length > 0) {
            realTimeFilterTerms[existingIndex] = facetsVal;
          } else {
            const removedFilter = realTimeFilterTerms.findIndex(
              (item: { type: string }) => item.type === facetsVal.type
            );
            realTimeFilterTerms.splice(removedFilter, 1);
          }
        }
        setFilterRealTimeTerms(realTimeFilterTerms);
      } else {
        if (filterType === 'hours' || filterType === 'bonvoyMembers') {
          const foundKey: string[] = [];
          // eslint-disable-next-line no-prototype-builtins
          for (const key in props) {
            selectedFilterValue.forEach((filter: any) => {
              // eslint-disable-next-line no-prototype-builtins
              if (props?.hasOwnProperty(key) && props?.[key] === filter.label) {
                foundKey.push(key);
              }
            });
          }
          if (foundKey) selectedDimensions = foundKey;
        } else if (filterType === 'city') {
          selectedDimensions = selectedFilterValue.map((obj: { label: any }) => {
            if (obj?.label?.includes('-')) {
              return obj?.label?.replace(' -', ',');
            } else return obj.label;
          });
        } else {
          selectedDimensions = selectedFilterValue.map((obj: { label: any }) => obj.label);
        }

        const filterValue = { type: filterType, dimensions: selectedDimensions };
        const existingIndex = realTimeFilterTerms.findIndex((item: { type: string }) => item.type === filterValue.type);

        if (existingIndex === -1) {
          realTimeFilterTerms.push(filterValue);
        } else {
          if (filterValue.dimensions.length > 0) {
            realTimeFilterTerms[existingIndex] = filterValue;
          } else {
            const removedFilter = realTimeFilterTerms.findIndex(
              (item: { type: string }) => item.type === filterValue.type
            );
            realTimeFilterTerms.splice(removedFilter, 1);
          }
        }
        setFilterRealTimeTerms(realTimeFilterTerms);
      }
      const isByLocationSearch = {
        country: country,
        state: state,
        facets: { terms: realTimeFilterTerms, ranges: realTimeFilterRanges },
      };
      const isByGeoLocationSearch = {
        latitude: latitude,
        longitude: longitude,
        facets: { terms: realTimeFilterTerms, ranges: realTimeFilterRanges },
      };
      const isByCountrySearch = {
        country: country,
        facets: { terms: realTimeFilterTerms, ranges: realTimeFilterRanges },
      };

      const isByOpenTextSearch = {
        query: userInputOpenSearchTerm,
        facets: { terms: realTimeFilterTerms, ranges: realTimeFilterRanges },
      };

      const locationQueryType =
        latitude && longitude
          ? searchRestaurantsByGeolocationFacets
          : state && country
          ? searchRestaurantsByLocationFacets
          : isOpenTextSearch
          ? searchRestaurantsByOpenTextFacets
          : searchRestaurantsByLocationFacets;
      getSearchResultsDetails(
        {
          variables: {
            search:
              latitude && longitude
                ? isByGeoLocationSearch
                : state && country
                ? isByLocationSearch
                : isOpenTextSearch
                ? isByOpenTextSearch
                : isByCountrySearch,
          },
          context: {
            headers: {
              // Any header, including x-request-id, can be passed ui-libraryin args with query.
              // If you don't pass it in the authLink will generate a random ID.

              'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
              'x-request-id': '1234',
              // 'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
              //  'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
              // 'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            },
          },
        },
        locationQueryType
      );
    } else if (props?.currentPage === CAMPAIGN_PAGE) {
      let selectedDimensions: string[] = [];

      if (filterType === 'city') {
        selectedDimensions = selectedFilterValue.map((obj: { label: any }) => {
          if (obj?.label?.includes('-')) {
            return obj?.label?.replace(' -', ',');
          } else return obj.label;
        });
      } else {
        selectedDimensions = selectedFilterValue.map((obj: { label: any }) => obj.label);
      }

      const filterValue = { type: filterType, dimensions: selectedDimensions };
      const existingIndex = realTimeFilterTerms.findIndex((item: { type: string }) => item.type === filterValue.type);

      if (existingIndex === -1) {
        realTimeFilterTerms.push(filterValue);
      } else {
        if (filterValue.dimensions.length > 0) {
          realTimeFilterTerms[existingIndex] = filterValue;
        } else {
          const removedFilter = realTimeFilterTerms.findIndex(
            (item: { type: string }) => item.type === filterValue.type
          );
          realTimeFilterTerms.splice(removedFilter, 1);
        }
      }

      setFilterRealTimeTerms(realTimeFilterTerms);

      getCampaignResultsDetails({
        variables: {
          input: {
            campaignId: campaignId,
            facets: { terms: realTimeFilterTerms },
          },
        },
        context: {
          headers: {
            // Any header, including x-request-id, can be passed ui-libraryin args with query.
            // If you don't pass it in the authLink will generate a random ID.

            'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
            'x-request-id': '1234',
            // 'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            //  'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            // 'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });
    } else if (props?.currentPage === GOLF_LOCATION_PAGE) {
      let selectedDimensions: string[] = [];
      if (filterType === RnBFILTERS.states)
        selectedDimensions = selectedFilterValue.map((obj: { code: any }) => obj.code);
      else selectedDimensions = selectedFilterValue.map((obj: { label: any }) => obj.label);

      const filterValue = { type: filterType, dimensions: selectedDimensions };
      const existingIndex = realTimeFilterTerms.findIndex((item: { type: string }) => item.type === filterValue.type);

      if (existingIndex === -1) {
        realTimeFilterTerms.push(filterValue);
      } else {
        if (filterValue.dimensions.length > 0) {
          realTimeFilterTerms[existingIndex] = filterValue;
        } else {
          const removedFilter = realTimeFilterTerms.findIndex(
            (item: { type: string }) => item.type === filterValue.type
          );
          realTimeFilterTerms.splice(removedFilter, 1);
        }
      }

      setFilterRealTimeTerms(realTimeFilterTerms);

      let updatedTerms = [
        ...realTimeFilterTerms,
        {
          type: golfActivitiesType,
          dimensions: [golfActivitiesDimension],
        },
      ];

      if (updatedTerms?.some(term => term.type.toUpperCase() === golfStateFilterType.toUpperCase())) {
        updatedTerms = updatedTerms.map(facetTerm => {
          if (facetTerm?.type?.toUpperCase() === golfStateFilterType.toUpperCase()) {
            const updatedFacetTerm = {
              ...facetTerm,
              type: facetTerm.type.toUpperCase(),
            };
            return updatedFacetTerm;
          } else return facetTerm;
        });
      } else {
        updatedTerms = [
          ...updatedTerms,
          {
            type: golfStateFilterType.toUpperCase(),
            dimensions: [],
          },
        ];
      }

      getGolfLocationDetails({
        variables: {
          search: {
            destination: props?.selectedTab,
            facets: {
              terms: updatedTerms,
            },
          },
        },
        context: {
          headers: {
            // Any header, including x-request-id, can be passed ui-libraryin args with query.
            // If you don't pass it in the authLink will generate a random ID.

            'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
            'x-request-id': '1234',
            // 'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            //  'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            // 'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });
    }
  };

  const handleFilterChange = (selectedBrandArr: any) => {
    brandSelection(selectedBrandArr);
    getNewFacets('brandId', selectedBrandArr);
  };

  const handleHoursFilterChange = (selectedHoursArr: any) => {
    hourSelection(selectedHoursArr);
    getNewFacets('hours', selectedHoursArr);
  };

  const handleCuisineFilterChange = (selectedCuisines: any) => {
    cuisineSelection(selectedCuisines);
    getNewFacets('cuisine', selectedCuisines);
  };

  const handleSeatingFilterChange = (selectedSeating: any) => {
    seatingSelection(selectedSeating);
    getNewFacets('seating', selectedSeating);
  };

  const handleDistanceFilterChange = (selectedDistance: any) => {
    distanceSelection(selectedDistance);
    getNewFacets('distance', selectedDistance);
  };

  const handleBonvoyFilterChange = (selectedbonvoy: any) => {
    bonvoySelection(selectedbonvoy);
    getNewFacets('bonvoyMembers', selectedbonvoy);
  };

  const handleVenueTypeFilterChange = (selectedVenueType: any) => {
    venueTypeSelection(selectedVenueType);
    getNewFacets('venueType', selectedVenueType);
  };

  const handleDietaryOptionsFilterChange = (selectedDietaryOptions: any) => {
    dietaryOptionsSelection(selectedDietaryOptions);
    getNewFacets('dietaryOptions', selectedDietaryOptions);
  };

  const handleExperiencesFilterChange = (selectedExperiences: any) => {
    experiencesSelection(selectedExperiences);
    getNewFacets('experiences', selectedExperiences);
  };

  const handleCityFilterChange = (selectedCity: any) => {
    citySelection(selectedCity);
    getNewFacets('city', selectedCity);
  };

  const handleStateProvinceFilterChange = (selectedstateProvince: any) => {
    stateProvinceDescSelection(selectedstateProvince);
    getNewFacets('stateProvinceDescription', selectedstateProvince);
  };

  const handleCountryDescFilterChange = (selectedCountryDesc: any) => {
    countryDescSelection(selectedCountryDesc);
    getNewFacets('countryDescription', selectedCountryDesc);
  };

  const handleCountryFilterChange = (selectedCountry: any) => {
    countrySelection(selectedCountry);
    getNewFacets('country', selectedCountry);
  };

  const handleStateFilterChange = (selectedStateData: any) => {
    statesSelection(selectedStateData);
    getNewFacets('states', selectedStateData);
  };

  const BrandFilterProps = {
    title: props?.restaurantsLocationLabel,
    showSeparator: false,
    brandCategories: getCategorizedBrands(currFilterData, props?.categorizedBrandsArray),
    defaultSelected: brands,
    onChange: handleFilterChange,
  };

  const getControlsData = (currentFilterData: any, filterName?: string) => {
    if (filterName === 'distance') {
      const desiredKeys = ['count', 'end', 'start'];
      const updatedDistanceOptions = currentFilterData?.map((obj: any) => {
        const newDistanceObj: any = {};
        desiredKeys?.forEach(key => {
          newDistanceObj[key] = obj[key];
        });
        return newDistanceObj;
      });
      const distanceUnit = getDistanceUnit(currentLocale ? currentLocale : 'en-US');
      const modifiedDistanceOptions = updatedDistanceOptions?.map((item: any) => ({
        count: item.count,
        label: `${parseFloat(item.start)} - ${parseFloat(item.end)} ${distanceUnit}`,
      }));
      return modifiedDistanceOptions;
    } else {
      const desiredKeys = ['code', 'count', 'label'];
      const updatedFilterOptions = currentFilterData?.map((obj: any) => {
        const newFilterOptionsObj: any = {};
        desiredKeys?.forEach(key => {
          newFilterOptionsObj[key] = obj[key];
        });
        return newFilterOptionsObj;
      });
      if (filterName === 'hours' || filterName === 'bonvoyMembers') {
        const modifiedFilterValueOptions = updatedFilterOptions?.map((item: any) => {
          const modifiedLabel = props?.[item.label] || item.label;
          return { ...item, label: modifiedLabel };
        });
        return modifiedFilterValueOptions;
      } else if (filterName === 'city') {
        const modifiedFilterValueOptions = updatedFilterOptions?.map((item: any) => {
          if (item?.label?.includes(',')) {
            return { ...item, label: item?.label?.replace(',', ' -') };
          } else return item;
        });
        return modifiedFilterValueOptions;
      } else return updatedFilterOptions;
    }
  };

  const HoursFilterProps = {
    title: props?.housrLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.hour)?.buckets,
      RnBFILTERS.hour
    ),
    enableShowMoreToggle: false,
    defaultSelected: hours,
    onChange: handleHoursFilterChange,
    name: 'hour',
    showSeparator: true,
  };

  const CuisineFilterProps = {
    title: filterButtonType === cuisineFilter ? '' : props?.cuisineLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.cuisine)?.buckets,
      RnBFILTERS.cuisine
    ),
    enableShowMoreToggle: true,
    defaultSelected: cuisine,
    onChange: handleCuisineFilterChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'cuisine',
    showSeparator: filterButtonType === allFilters,
  };

  const SeatingFilterProps = {
    title: props?.seatingLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.seating)?.buckets,
      RnBFILTERS.seating
    ),
    enableShowMoreToggle: true,
    defaultSelected: seating,
    onChange: handleSeatingFilterChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'seating',
    showSeparator: true,
  };

  const DistanceFilterProps = {
    title: props?.distanceLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.distance)?.buckets,
      RnBFILTERS.distance
    ),
    enableShowMoreToggle: false,
    defaultSelected: distance,
    onChange: handleDistanceFilterChange,
    name: 'distance',
    showSeparator: true,
  };

  const CityProps = {
    title: props?.cityLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.city)?.buckets,
      RnBFILTERS.city
    ),
    enableShowMoreToggle: true,
    defaultSelected: city,
    onChange: handleCityFilterChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'city',
    showSeparator: true,
  };

  const stateProvinceDescriptionProps = {
    title: props?.stateProvinceDescriptionLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.stateProvinceDescription)?.buckets,
      RnBFILTERS.stateProvinceDescription
    ),
    enableShowMoreToggle: true,
    defaultSelected: stateProvinceDescription,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    onChange: handleStateProvinceFilterChange,
    name: 'stateProvinceDescription',
    showSeparator: true,
  };

  const statesDataProps = {
    title: props?.stateProvinceDescriptionLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.states)?.buckets,
      RnBFILTERS.states
    ),
    enableShowMoreToggle: true,
    defaultSelected: statesData,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    onChange: handleStateFilterChange,
    name: 'states',
    showSeparator: true,
  };

  const CountryDescProps = {
    title: props?.countryDescriptionLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.countryDescription)?.buckets,
      RnBFILTERS.countryDescription
    ),
    enableShowMoreToggle: true,
    defaultSelected: countryDescription,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    onChange: handleCountryDescFilterChange,
    name: 'Country',
    showSeparator: filterButtonType === allFilters ? true : false,
  };

  const CountryProps = {
    title: props?.countryDescriptionLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.country)?.buckets,
      RnBFILTERS.country
    ),
    enableShowMoreToggle: true,
    defaultSelected: countryData,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    onChange: handleCountryFilterChange,
    name: 'Country',
    showSeparator: filterButtonType === allFilters ? true : false,
  };

  const BonvoyMemberProps = {
    title: props?.bonvoyMembersLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.bonvoyMember)?.buckets,
      RnBFILTERS.bonvoyMember
    ),
    enableShowMoreToggle: false,
    defaultSelected: bonvoyMembers,
    onChange: handleBonvoyFilterChange,
    name: 'bonvoy',
    showSeparator: true,
  };

  const VenueTypeFilterProps = {
    title: props?.venueTypeLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.venueType)?.buckets,
      RnBFILTERS.venueType
    ),
    enableShowMoreToggle: true,
    defaultSelected: venueType,
    onChange: handleVenueTypeFilterChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'venueType',
    showSeparator: true,
  };

  const DietaryOptionsFilterProps = {
    title: props?.dietaryOptionsLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.dietaryOptions)?.buckets,
      RnBFILTERS.dietaryOptions
    ),
    enableShowMoreToggle: true,
    defaultSelected: dietaryOptions,
    onChange: handleDietaryOptionsFilterChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'Dietary Options',
    showSeparator: true,
  };

  const ExperiencesFilterProps = {
    title: props?.experiencesLabel,
    controls: getControlsData(
      currFilterData?.find((key: any) => key.type.code === RnBFILTERS.experiences)?.buckets,
      RnBFILTERS.experiences
    ),
    enableShowMoreToggle: true,
    defaultSelected: experiences,
    onChange: handleExperiencesFilterChange,
    showMoreCount: 8,
    showMoreLabel: 'Show More',
    showLessLabel: 'Show Less',
    name: 'Experiences',
    showSeparator: true,
  };

  const componentMap = {
    hours: () => (HoursFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...HoursFilterProps} /> : null),
    cuisine: () => (CuisineFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...CuisineFilterProps} /> : null),
    distance: () =>
      DistanceFilterProps?.controls?.length > 0 ? <RadiobuttonControlsList {...DistanceFilterProps} /> : null,
    seating: () => (SeatingFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...SeatingFilterProps} /> : null),
    brandId: () => <BrandFilter {...BrandFilterProps} />,
    bonvoyMembers: () =>
      BonvoyMemberProps?.controls?.length > 0 ? <ToggleControlsList {...BonvoyMemberProps} /> : null,
    venueType: () =>
      VenueTypeFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...VenueTypeFilterProps} /> : null,
    dietaryOptions: () =>
      DietaryOptionsFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...DietaryOptionsFilterProps} /> : null,
    experiences: () =>
      ExperiencesFilterProps?.controls?.length > 0 ? <CheckboxControlsList {...ExperiencesFilterProps} /> : null,
    city: () => (CityProps?.controls?.length > 0 ? <CheckboxControlsList {...CityProps} /> : null),
    stateProvinceDescription: () =>
      stateProvinceDescriptionProps?.controls?.length > 0 ? (
        <CheckboxControlsList {...stateProvinceDescriptionProps} />
      ) : null,
    states: () => (statesDataProps?.controls?.length > 0 ? <CheckboxControlsList {...statesDataProps} /> : null),
    countryDescription: () =>
      CountryDescProps?.controls?.length > 0 ? <CheckboxControlsList {...CountryDescProps} /> : null,
    country: () => (CountryProps?.controls?.length > 0 ? <CheckboxControlsList {...CountryProps} /> : null),
  };

  const {
    ALL_FILTER_BUTTON,
    SELECTED_FILTER_PILL,
    SEARCH_RESULT_PAGE,
    SEARCH_FILTER_MODAL,
    INTERNAL_LINK,
    BONVOY_FILTER,
    BRAND_FILTER,
    CUISINE_FILTER,
    DISTANCE_FILTER,
    HOURS_FILTER,
    SEATING_FILTER,
    VENUE_TYPE_FILTER,
    DIETARY_OPTIONS_FILTER,
    EXPERIENCES_FILTER,
    CITY_FILTER,
    STATE_PROVINCE_DESC_FILTER,
    COUNTRY_DESC_FILTER,
    LOCATION_FILTER_BUTTON,
    CUISINE_FILTER_BUTTON,
    SEARCH_FILTER_CLEAR,
    SEARCH_FILTER_APPLY,
    SEARCH_FILTER_CLOSE_ICON,
  } = TRACKING_CONSTANT;

  useEffect(() => {
    setFilterCount(
      hours?.length +
        cuisine?.length +
        distance?.length +
        bonvoyMembers?.length +
        seating?.length +
        brands?.length +
        venueType?.length +
        dietaryOptions?.length +
        experiences?.length +
        city?.length +
        stateProvinceDescription?.length +
        countryDescription?.length +
        countryData?.length +
        statesData?.length
    );
    // getNewFacets(hours);
  }, [
    hours,
    cuisine,
    brands,
    distance,
    bonvoyMembers,
    seating,
    venueType,
    dietaryOptions,
    experiences,
    city,
    stateProvinceDescription,
    countryDescription,
    countryData,
    statesData,
  ]);

  const applyBtnHandler = (): void => {
    setPaginationChange(false);
    setPageNumber(1);
    setIsLoaderVisible(true);
    if (hours?.length) {
      setFacetsPayload('hours', hours);
    }
    if (cuisine?.length) {
      setFacetsPayload('cuisine', cuisine);
    }
    if (seating?.length) {
      setFacetsPayload('seating', seating);
    }
    if (bonvoyMembers?.length) {
      setFacetsPayload('bonvoyMembers', bonvoyMembers);
    }
    if (brands?.length) {
      setFacetsPayload('brandId', brands);
    }
    if (distance?.length) {
      setFacetsPayload('distance', distance);
    }
    if (venueType?.length) {
      setFacetsPayload('venueType', venueType);
    }
    if (dietaryOptions?.length) {
      setFacetsPayload('dietaryOptions', dietaryOptions);
    }
    if (experiences?.length) {
      setFacetsPayload('experiences', experiences);
    }
    if (city?.length) {
      setFacetsPayload('city', city);
    }
    if (stateProvinceDescription?.length) {
      setFacetsPayload('stateProvinceDescription', stateProvinceDescription);
    }
    if (statesData?.length) {
      setFacetsPayload('states', statesData);
    }
    if (countryDescription?.length) {
      setFacetsPayload('countryDescription', countryDescription);
    }
    if (countryData?.length) {
      setFacetsPayload('country', countryData);
    }
    setSelectedFilter(selectedFilters);
    setFilter([...selectedFilter, selectedFilter]);
    setFilterCount(
      hours?.length +
        cuisine?.length +
        distance?.length +
        bonvoyMembers?.length +
        seating?.length +
        brands?.length +
        venueType?.length +
        dietaryOptions?.length +
        experiences?.length +
        city?.length +
        stateProvinceDescription?.length +
        countryDescription?.length +
        countryData?.length +
        statesData?.length
    );
    setFilterQueryParams(selectedFilters);
    if (props?.trackingProperties?.impressionTrack) {
      const tracking = getTrackingProperties(props?.trackingProperties || {}, ',', '');
      if (window.impressionArr?.includes(`${tracking.trackingString}${'Applied Search Filters'}`)) {
        window.impressionArr = [];
      }
      trackImpression(props?.trackingProperties || {}, 'Applied Search Filters' || '');
    }
  };

  const clearBtnHandler = (): void => {
    setIsLoaderVisible(true);
    setFilterRealTimeRanges([]);
    setFilterRealTimeTerms([]);

    if (filterButtonType === allFilters) {
      hourSelection([]);
      cuisineSelection([]);
      distanceSelection([]);
      seatingSelection([]);
      bonvoySelection([]);
      brandSelection([]);
      venueTypeSelection([]);
      dietaryOptionsSelection([]);
      experiencesSelection([]);
      citySelection([]);
      stateProvinceDescSelection([]);
      countryDescSelection([]);
      countrySelection([]);
      statesSelection([]);
      setSelectedFilter([]);
      setFilter([]);
      setFilterCount(0);
      urlparams = '';
      setFilterQueryParams([]);
    } else if (filterButtonType === locationFilter) {
      citySelection([]);
      stateProvinceDescSelection([]);
      countryDescSelection([]);
      countrySelection([]);
      setFilterQueryParams([
        { type: 'hours', dimensions: hours },
        { type: 'cuisine', dimensions: cuisine },
        { type: 'distance', dimensions: distance },
        { type: 'seating', dimensions: seating },
        { type: 'bonvoyMembers', dimensions: bonvoyMembers },
        { type: 'brandId', dimensions: brands },
        { type: 'venueType', dimensions: venueType },
        { type: 'dietaryOptions', dimensions: dietaryOptions },
        { type: 'experiences', dimensions: experiences },
        { type: 'city', dimensions: [] },
        { type: 'stateProvinceDescription', dimensions: [] },
        { type: 'countryDescription', dimensions: [] },
        { type: 'country', dimensions: [] },
        { type: 'states', dimensions: [] },
      ]);
      setPaginationChange(false);
      setPageNumber(1);
    } else if (filterButtonType === cuisineFilter) {
      cuisineSelection([]);
      setFilterQueryParams([
        { type: 'hours', dimensions: hours },
        { type: 'cuisine', dimensions: [] },
        { type: 'distance', dimensions: distance },
        { type: 'seating', dimensions: seating },
        { type: 'bonvoyMembers', dimensions: bonvoyMembers },
        { type: 'brandId', dimensions: brands },
        { type: 'venueType', dimensions: venueType },
        { type: 'dietaryOptions', dimensions: dietaryOptions },
        { type: 'experiences', dimensions: experiences },
        { type: 'city', dimensions: city },
        { type: 'stateProvinceDescription', dimensions: stateProvinceDescription },
        { type: 'countryDescription', dimensions: countryDescription },
        { type: 'country', dimensions: countryData },
        { type: 'states', dimensions: statesData },
      ]);
      setPaginationChange(false);
      setPageNumber(1);
    }
    setCurrFilterData(facetsData);
    setPopupOpenState(false);
  };

  const setFacetsPayload = (type: string, filterName: any): void => {
    const selectedDimensions: any[] = [];
    if (type === 'brandId') {
      filterName.forEach((filterName: any): any => selectedDimensions.push(filterName.brandTagId));
    } else if (type === RnBFILTERS.states) {
      filterName.forEach((filterName: any): any => selectedDimensions.push(filterName.code));
    } else {
      filterName.forEach((filterName: any): any => selectedDimensions.push(filterName.label));
    }
    filterName.forEach((item: any): void => {
      urlparams = urlparams + item + '|';
    });

    selectedFilters.push({
      type: type,
      dimensions: selectedDimensions,
    });
  };

  const setFilterQueryParams = (selectedFilters: any) => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramKeys: any = [];
    searchParams.forEach((_value, key) => {
      paramKeys.push(key);
    });

    paramKeys.forEach((item: any): void => {
      if (
        item !== 'lat' &&
        item !== 'long' &&
        item !== 'term' &&
        item !== 'term1' &&
        item !== 'term2' &&
        item !== 'term3' &&
        item !== otsUrlTypeTerm
      ) {
        searchParams.delete(item);
      }
    });
    if (selectedFilters) {
      selectedFilters.forEach((item: any): void => {
        const isFilterStringArr = item?.dimensions?.every((filter: any) => typeof filter === 'string');
        if (item?.dimensions?.length > 0) {
          if (isFilterStringArr) {
            searchParams.append(item.type, item.dimensions);
          } else {
            const filterLabel = item.dimensions.map((filterName: any) => {
              if (item.type === RnBFILTERS.states) return filterName.code;
              else return filterName.label || filterName.brandTagId;
            });
            if (filterLabel?.length > 0) searchParams.append(item.type, filterLabel);
          }
        }
      });
    }
    const queryString = searchParams.toString();
    if (queryString) window.history.pushState({}, '', `?${queryString}`);
    else window.history.pushState(null, '', window.location.pathname);
    setQueryParam(queryString);
  };

  useEffect(() => {
    setCurrFilterData(filterData);
  }, [filterData, currFilterData]);

  useEffect(() => {
    setCurrFilterData(currFilterData);
  }, [currFilterData]);

  useEffect(() => {
    setFilterOrderList(props?.filtersOrderList);
  }, [props?.filtersOrderList]);

  useEffect(() => {
    if (filterCount > 0) {
      setShowSelectedPills(true);
    }
  }, [filterCount]);

  useEffect(() => {
    scrollSection(scrollableSectionRef);
  }, [props?.isMobileViewPort]);

  useEffect(() => {
    const direction = getDirection();
    if (direction === 'rtl') {
      const radioBtns = document.getElementsByClassName('keydown-anchor');
      const radioBtnsLength = radioBtns?.length;
      for (let i = 0; i < radioBtnsLength; i++) {
        if (radioBtns[i].getAttribute('dir') !== 'ltr') {
          radioBtns[i].setAttribute('dir', 'ltr');
        }
      }
    }
  }, [popupOpenState]);

  const updateFilterData = (filterName: any, filterData: any, text: any, storeFilterUpdater: any) => {
    let deSelectedFilter: any, updatedFilter;
    if (filterName === 'brandId') {
      deSelectedFilter = { brandTagId: text };
      updatedFilter = filterData?.filter((item: any) => item.brandTagId !== deSelectedFilter.brandTagId);
    } else {
      deSelectedFilter = { label: text };
      updatedFilter = filterData?.filter((item: any) => item.label !== deSelectedFilter.label);
    }
    storeFilterUpdater(updatedFilter);
    setFacetsPayload(filterName, updatedFilter);
    setSelectedFilter(updatedFilter);
    setFilter([...selectedFilter, updatedFilter]);
    return updatedFilter;
  };

  const updateSelectedFilter = (text: string, filterType: string) => {
    let updatedHours,
      updatedCuisine,
      updatedSeating,
      updatedBonvoySelections,
      updatedDistance: any,
      updatedBrands,
      updatedVenueType,
      updatedDietaryOptions,
      updatedExperiences,
      updatedCity,
      updatedStateProvinceDescription,
      updatedCountryDescription,
      updatedCountry,
      updatedStates;
    switch (filterType) {
      case 'hours':
        updatedHours = updateFilterData('hours', hours, text, hourSelection);
        break;
      case 'cuisine':
        updatedCuisine = updateFilterData('cuisine', cuisine, text, cuisineSelection);
        break;
      case 'distance': {
        updatedDistance = [];
        distanceSelection(updatedDistance);
        setFacetsPayload('distance', updatedDistance);
        setSelectedFilter(updatedDistance);
        setFilter([...selectedFilter, updatedDistance]);
        break;
      }
      case 'seating':
        updatedSeating = updateFilterData('seating', seating, text, seatingSelection);
        break;
      case 'bonvoyMembers':
        updatedBonvoySelections = updateFilterData('bonvoyMembers', bonvoyMembers, text, bonvoySelection);
        break;
      case 'brandId':
        updatedBrands = updateFilterData('brandId', brands, text, brandSelection);
        break;
      case 'venueType':
        updatedVenueType = updateFilterData('venueType', venueType, text, venueTypeSelection);
        break;
      case 'dietaryOptions':
        updatedDietaryOptions = updateFilterData('dietaryOptions', dietaryOptions, text, dietaryOptionsSelection);
        break;
      case 'experiences':
        updatedExperiences = updateFilterData('experiences', experiences, text, experiencesSelection);
        break;
      case 'city':
        updatedCity = updateFilterData('city', city, text, citySelection);
        break;
      case 'stateProvinceDescription':
        updatedStateProvinceDescription = updateFilterData(
          'stateProvinceDescription',
          stateProvinceDescription,
          text,
          stateProvinceDescSelection
        );
        break;
      case 'countryDescription':
        updatedCountryDescription = updateFilterData(
          'countryDescription',
          countryDescription,
          text,
          countryDescSelection
        );
        break;
      case 'country':
        updatedCountry = updateFilterData('country', countryData, text, countrySelection);
        break;
      case 'states':
        updatedStates = updateFilterData('states', statesData, text, statesSelection);
        break;
    }

    setFilterQueryParams([
      { type: 'hours', dimensions: updatedHours || hours },
      { type: 'cuisine', dimensions: updatedCuisine || cuisine },
      { type: 'distance', dimensions: updatedDistance || distance },
      { type: 'seating', dimensions: updatedSeating || seating },
      { type: 'bonvoyMembers', dimensions: updatedBonvoySelections || bonvoyMembers },
      { type: 'brandId', dimensions: updatedBrands || brands },
      { type: 'venueType', dimensions: updatedVenueType || venueType },
      { type: 'dietaryOptions', dimensions: updatedDietaryOptions || dietaryOptions },
      { type: 'experiences', dimensions: updatedExperiences || experiences },
      { type: 'city', dimensions: updatedCity || city },
      { type: 'stateProvinceDescription', dimensions: updatedStateProvinceDescription || stateProvinceDescription },
      { type: 'countryDescription', dimensions: updatedCountryDescription || countryDescription },
      { type: 'country', dimensions: updatedCountry || countryData },
      { type: 'states', dimensions: updatedStates || statesData },
    ]);

    setPaginationChange(false);
    setPageNumber(1);
  };

  const resetFacetsPayload = () => {
    setCurrFilterData(filterData);
    const query = new URLSearchParams(window.location.search);

    let facetsVal: { type: string; dimensions: string[] } = {
      type: '',
      dimensions: [],
    };
    //const routeKeys = Object.keys(router.query);
    const routeKeys: any = [];
    query.forEach((_value, key) => {
      routeKeys.push(key);
    });
    const filteredArray = routeKeys?.filter((value: any) => {
      return props?.filtersOrderList?.some((obj: any) => obj.filterName === value);
    });

    hourSelection([]);
    cuisineSelection([]);
    distanceSelection([]);
    seatingSelection([]);
    bonvoySelection([]);
    brandSelection([]);
    venueTypeSelection([]);
    dietaryOptionsSelection([]);
    experiencesSelection([]);
    citySelection([]);
    stateProvinceDescSelection([]);
    countryDescSelection([]);
    countrySelection([]);
    statesSelection([]);

    if (filteredArray?.length) {
      filteredArray.forEach((filter: any) => {
        if (filter === 'brandId') {
          facetsVal = { type: '', dimensions: [] };
          let selectedVal: any = [];
          const values: any = query.get(filter) ? query.get(filter) : '';
          selectedVal = values.split(',');
          facetsVal.type = filter;
          const updatedBrands: any = [];
          brands?.forEach((item: any) => {
            if (selectedVal.includes(item.brandTagId)) {
              updatedBrands.push(item);
            }
          });
          brandSelection(updatedBrands);
        } else if (filter === 'distance') {
          const distanceFacetsVal: {
            type: string;
            endpoints: string[];
            dimensions: string[];
          } = { type: '', endpoints: [], dimensions: ['1'] };
          const values: any = query.get(filter) ? query.get(filter) : '';
          distanceFacetsVal.type = filter;
          distanceFacetsVal['endpoints'] = [values.split(' ')[0], values.split(' ')[2]];
          storeUpdater(filter);
        } else {
          storeUpdater(filter);
        }
      });
    }
  };

  const storeUpdater = (filter: any) => {
    const query = new URLSearchParams(window.location.search);
    const facetsVal = { type: '', dimensions: [] };
    let selectedVal: any = [];
    const values: any = query.get(filter) ? query.get(filter) : '';
    selectedVal = values.split(',');
    facetsVal.type = filter;
    const updatedFilterValues: any = [];
    switch (facetsVal.type) {
      case 'hours':
        updateStoreData(selectedVal, updatedFilterValues, 'hour', hourSelection);
        break;
      case 'cuisine':
        updateStoreData(selectedVal, updatedFilterValues, 'cuisine', cuisineSelection);
        break;
      case 'seating':
        updateStoreData(selectedVal, updatedFilterValues, 'seating', seatingSelection);
        break;
      case 'bonvoyMembers':
        updateStoreData(selectedVal, updatedFilterValues, 'bonvoyMember', bonvoySelection);
        break;
      case 'brandId':
        brandSelection(facetsVal['dimensions']);
        break;
      case 'distance': {
        const updatedDistanceFilterOptions = updateDistanceFilterUnits(currentLocale ? currentLocale : 'en-US');
        const selectedDistance = updatedDistanceFilterOptions.filter(
          (distance: any) => distance.label === selectedVal[0]
        );
        distanceSelection(selectedDistance);
        break;
      }
      case 'venueType':
        updateStoreData(selectedVal, updatedFilterValues, 'venueType', venueTypeSelection);
        break;
      case 'dietaryOptions':
        updateStoreData(selectedVal, updatedFilterValues, 'dietaryOptions', dietaryOptionsSelection);
        break;
      case 'experiences':
        updateStoreData(selectedVal, updatedFilterValues, 'experiences', experiencesSelection);
        break;
      case 'city':
        updateStoreData(selectedVal, updatedFilterValues, 'city', citySelection);
        break;
      case 'stateProvinceDescription':
        updateStoreData(selectedVal, updatedFilterValues, 'stateProvinceDescription', stateProvinceDescSelection);
        break;
      case 'countryDescription':
        updateStoreData(selectedVal, updatedFilterValues, 'countryDescription', countryDescSelection);
        break;
      case 'country':
        updateStoreData(selectedVal, updatedFilterValues, 'country', countrySelection);
        break;
      case 'states':
        updateStoreData(selectedVal, updatedFilterValues, 'states', statesSelection);
        break;
    }
  };

  const renderSelectedFilterPill = (filterData: any, filterName: any, trackedFilter: any) =>
    filterData &&
    showSelectedPills &&
    filterData?.map((filter: any, index: number) => {
      return (
        <SearchButtonPill
          text={filterName === 'brandId' ? filter.brandTagId : filter.label}
          key={index}
          filterType={filterName}
          onSelectedPillClick={updateSelectedFilter}
          customTrackValue={`${SELECTED_FILTER_PILL}|${trackedFilter} ${filter}|${INTERNAL_LINK}`}
        />
      );
    });

  const getModalData = () => {
    if (filterButtonType === allFilters) {
      const data = filterOrderList?.map((filterItem: any, index: number) => {
        const { filterName } = filterItem;
        const Component = componentMap[filterName as keyof typeof componentMap];
        if (Component) {
          return <div key={index}>{Component()}</div>;
        }
        return null;
      });
      return data;
    } else if (filterButtonType === locationFilter) {
      let locationFilterList = [RnBFILTERS.city, RnBFILTERS.stateProvinceDescription];

      if (props?.currentPage === CAMPAIGN_PAGE) locationFilterList = [...locationFilterList, RnBFILTERS.country];
      else if (props?.currentPage === SEARCH_RESULTS_PAGE)
        locationFilterList = [...locationFilterList, RnBFILTERS.countryDescription];

      const data = locationFilterList?.map((filterItem, index) => {
        const Component = componentMap[filterItem];
        if (Component) {
          return <div key={index}>{Component()}</div>;
        }
        return null;
      });
      return data;
    } else if (filterButtonType === cuisineFilter) {
      return CuisineFilterProps?.controls.length > 0 ? (
        <div className={isMobileViewPort ? 'mt-4' : 'pt-5 mt-1'}>
          <CheckboxControlsList {...CuisineFilterProps} />
        </div>
      ) : null;
    } else return null;
  };

  return (
    <StyledSearchAllFilters className="pt-3 pt-lg-5 pt-xl-5 d-flex d-wrap">
      <div
        className={clsx('filter-section', props?.isMobileViewPort ? 'scrollable-section container p-0' : '')}
        ref={scrollableSectionRef}
      >
        <div className="d-flex filters-button-container">
          <Button
            callback={(): void => {
              setPopupOpenState(!popupOpenState);
              setFilterButtonType(allFilters);
            }}
            className={clsx(
              'm-button-primary-inverse t-label-alt-m all-filter sm-search-result-all-filter-component-button m-button-gallery',
              filterCount > 0 ? 'filter-option-button__active' : 'filter-option-button'
            )}
            custom_click_track_value={`${SEARCH_RESULT_PAGE}|${ALL_FILTER_BUTTON}|${INTERNAL_LINK}`}
          >
            {filterCount <= 0 ? (
              <span className="icon-s icon-dining-filter"> {props?.filterLabel}</span>
            ) : (
              <span>
                <span className="filter-count t-font-xs">{filterCount}</span> {props?.filterLabel}
              </span>
            )}
          </Button>
          {(city.length > 0 ||
            stateProvinceDescription.length > 0 ||
            countryDescription.length > 0 ||
            countryData.length > 0) && (
            <Button
              callback={(): void => {
                setPopupOpenState(!popupOpenState);
                setFilterButtonType(locationFilter);
              }}
              className={clsx(
                'm-button-primary-inverse t-label-alt-m all-filter sm-search-result-all-filter-component-button m-button-gallery',
                city.length > 0 ||
                  stateProvinceDescription.length > 0 ||
                  countryDescription.length > 0 ||
                  countryData.length > 0
                  ? 'filter-option-button__active'
                  : 'filter-option-button'
              )}
              custom_click_track_value={`${SEARCH_RESULT_PAGE}|${LOCATION_FILTER_BUTTON}|${INTERNAL_LINK}`}
            >
              {props?.locationLabel}
            </Button>
          )}
          {cuisine.length > 0 && (
            <Button
              callback={(): void => {
                setPopupOpenState(!popupOpenState);
                setFilterButtonType(cuisineFilter);
              }}
              className={clsx(
                'm-button-primary-inverse t-label-alt-m all-filter sm-search-result-all-filter-component-button m-button-gallery',
                cuisine.length > 0 ? 'filter-option-button__active' : 'filter-option-button'
              )}
              custom_click_track_value={`${SEARCH_RESULT_PAGE}|${CUISINE_FILTER_BUTTON}|${INTERNAL_LINK}`}
            >
              {props?.cuisineLabel}
            </Button>
          )}
        </div>
        <div className={clsx('d-flex flex-nowrap', !props?.isMobileViewPort ? 'scrollable-section' : '')}>
          {renderSelectedFilterPill(hours, 'hours', HOURS_FILTER)}
          {renderSelectedFilterPill(cuisine, 'cuisine', CUISINE_FILTER)}
          {distance &&
            showSelectedPills &&
            distance?.map((filter: any, index: number) => {
              return (
                <SearchButtonPill
                  text={filter.label}
                  key={index}
                  filterType="distance"
                  onSelectedPillClick={updateSelectedFilter}
                  customTrackValue={`${SELECTED_FILTER_PILL}|${DISTANCE_FILTER} ${filter}|${INTERNAL_LINK}`}
                />
              );
            })}
          {renderSelectedFilterPill(seating, 'seating', SEATING_FILTER)}
          {renderSelectedFilterPill(bonvoyMembers, 'bonvoyMembers', BONVOY_FILTER)}
          {renderSelectedFilterPill(brands, 'brandId', BRAND_FILTER)}
          {renderSelectedFilterPill(venueType, 'venueType', VENUE_TYPE_FILTER)}
          {renderSelectedFilterPill(dietaryOptions, 'dietaryOptions', DIETARY_OPTIONS_FILTER)}
          {renderSelectedFilterPill(experiences, 'experiences', EXPERIENCES_FILTER)}
          {renderSelectedFilterPill(city, 'city', CITY_FILTER)}
          {renderSelectedFilterPill(stateProvinceDescription, 'stateProvinceDescription', STATE_PROVINCE_DESC_FILTER)}
          {renderSelectedFilterPill(countryDescription, 'countryDescription', COUNTRY_DESC_FILTER)}
          {renderSelectedFilterPill(countryData, 'country', COUNTRY_DESC_FILTER)}
          {renderSelectedFilterPill(statesData, 'states', STATE_PROVINCE_DESC_FILTER)}
        </div>
      </div>
      {popupOpenState && (
        <Modal
          className={`custom-scrollbar modal-main-container  ${popupOpenState ? '' : 'close-popup-header'}`}
          show={true}
          handleBlur={true}
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
          allFilterPopUp={true}
          onClose={(): void => {
            resetFacetsPayload();
          }}
        >
          {isNewFacetsLoading && (
            <div
              className="loader-container d-flex align-items-center justify-content-center"
              data-testid="facets-spinner"
            >
              <div className="m-spinner-m"></div>
            </div>
          )}
          <Modal.Header
            className="modal-header t-subtitle-xl"
            labelText={
              filterButtonType === allFilters
                ? props?.filtersHeaderLabel
                : filterButtonType === locationFilter
                ? props?.locationLabel
                : filterButtonType === cuisineFilter
                ? props?.cuisineLabel
                : ''
            }
            popupHeaderOnCLoseFunc={(): void => {
              setPopupOpenState(false);
              resetFacetsPayload();
            }}
            closeIconClickTrackValue={SEARCH_FILTER_CLOSE_ICON}
          />
          <Modal.Body
            className={clsx(
              'all-filters custom-scrollbar',
              filterButtonType === locationFilter || filterButtonType === cuisineFilter ? 'fixed-size-modal' : '',
              isDirectionRTL ? 'text-right' : ''
            )}
          >
            {getModalData()}
          </Modal.Body>
          <Modal.Footer
            clearClickHandler={clearBtnHandler}
            setPopUpOpenState={setPopupOpenState}
            applyBtnClickHandler={applyBtnHandler}
            clearButtonLabel={props?.clearLabel}
            applyLabel={props?.applyCtaLabel}
            className="modal-footer"
            clickTrackingLoc={SEARCH_FILTER_MODAL}
            clearBtnClickTrackValue={SEARCH_FILTER_CLEAR}
            applyBtnClickTrackValue={SEARCH_FILTER_APPLY}
          />
        </Modal>
      )}
    </StyledSearchAllFilters>
  );
};
