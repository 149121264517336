// Imports for external libraries go here.
import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Config, EditableComponent, MappedComponentProperties } from '@adobe/aem-react-editable-components';
import { BannerLayered, BannerLayeredProps } from '@marriott/mi-ui-library';

import { useServiceStore, serviceStore } from '../../modules/store/serviceStore';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { JoinBannerProps } from './JoinBanner.types';
import { StyledJoinBanner } from './JoinBanner.styles';

// Use named rather than default exports.
export const JoinBanner: FC<JoinBannerProps> = (pageProps: JoinBannerProps) => {
  const router = useRouter();
  const returnURL = router?.asPath;
  const serviceData = useServiceStore((state: serviceStore) => state.serviceData);
  const [updateReturnUrl, setUpdateReturnUrl] = useState<string>('');
  const [topSpacing, setTopSpacing] = useState(false);

  useEffect(() => {
    const element = document?.getElementsByClassName('bl_cta-wrapper bl_cta-center');
    if (element) {
      element?.[0]?.children?.[1]?.setAttribute(
        'data-custom_click_track_value',
        'Become a Marriott Bonvoy Member|Sign In|external'
      );
      element?.[0]?.children?.[1]?.setAttribute(
        'custom_click_track_value',
        'Become a Marriott Bonvoy Member|Sign In|external'
      );
      element?.[0]?.children?.[1]?.setAttribute('data-is-click-track', 'true');
    }
  }, []);

  useEffect(() => {
    if (serviceData && serviceData.merchandisingAvailable && !serviceData.servicesLoaded) {
      setTopSpacing(true);
    } else {
      setTopSpacing(false);
    }
  }, [serviceData]);

  useEffect(() => {
    const bannerLayeredModel: BannerLayeredProps = pageProps && pageProps.model;
    const secondaryCtaLink: BannerLayeredProps['secondaryCtaLink'] = bannerLayeredModel?.secondaryCtaLink;
    const updateReturnUrl: string | undefined =
      secondaryCtaLink && secondaryCtaLink?.includes('_PAGEURL_')
        ? secondaryCtaLink?.replace('_PAGEURL_', encodeURIComponent(encodeURIComponent(returnURL)))
        : secondaryCtaLink;
    setUpdateReturnUrl(updateReturnUrl ?? '');
  }, [pageProps, returnURL]);

  return (
    <StyledJoinBanner
      data-testid="bannerlayered"
      data-component-name="o-ppv5-bannerlayered"
      className={`${topSpacing ? 'mt-3 pt-4' : ''}`}
    >
      <BannerLayered
        {...pageProps?.model}
        secondaryCtaLink={updateReturnUrl}
        styleclass={'inverse'}
        custom_click_track_value_primary="Become a Marriott Bonvoy Member|Unlock extraordinary experiences with the Marriott Bonvoy™ app.|external"
      />
    </StyledJoinBanner>
  );
};

export const JoinBannerConfig = {
  emptyLabel: 'MarriottBonvoyBannerComponent',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `mi-aem-common-spa/components/content/bannerlayered`,
} as Config<MappedComponentProperties>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JoinBannerExperienceEditable = (props: any) => {
  return (
    <div data-testid="join-banner-editable" className="standard">
      {props?.cqPath?.includes('experiencefragment') ? (
        <JoinBanner {...props} />
      ) : (
        <EditableComponent config={JoinBannerConfig} {...props}>
          <JoinBanner {...props} />
        </EditableComponent>
      )}
    </div>
  );
};
