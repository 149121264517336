import {
  TrackingPropsInterface,
  TrackingProps,
  IMetaData,
} from '../organisms/MerchandisingHeroBanner/MerchandisingHeroBanner.types';
import { canUseDOM } from '@marriott/shared/mi-helper-utils';
import { getEVAR48, getSPID } from '@marriott/mi-headless-utils';

export const getTrackingPropertiesDynamic = (
  trackingProperties: TrackingProps,
  metaData: IMetaData,
  isAuth: boolean,
  isTargetContentError: boolean,
  mboxParameter = ''
): TrackingPropsInterface => {
  /**
   *
   * @returns it returns the value of atCCeVar48
   * @description this fucntion returns value of atCCeVar48, conditionally,
   *
   */
  const getAtCCevar48 = () => {
    const isCobrand =
      isTargetContentError || !mboxParameter
        ? trackingProperties?.merchandisingCategory === 'cobrandNonPointsMath'
        : metaData?.type === 'CoBrand';

    if (canUseDOM && isCobrand) {
      // If type in MBOP response is cobrand then construct evar48
      if (isTargetContentError || !mboxParameter) return trackingProperties?.atCCeVar48;
      else return getEVAR48({ metaData: metaData });
    }
    // if type in MBOP response is non cobrand then pass empty in evar48
    return '';
  };

  return {
    trackingContentPosition: metaData
      ? mboxParameter?.replace(/-mbox$/, '')
      : trackingProperties?.trackingContentPosition,
    trackingDescription: metaData ? 'dca' : trackingProperties?.trackingDescription,
    trackingOfferType: metaData ? 'dca' : trackingProperties?.trackingOfferType,
    trackingTag: metaData ? String(metaData?.merTag) : trackingProperties?.trackingTag1,
    additionalTrackingVariables: metaData
      ? metaData?.optionalFields
        ? 'true'
        : 'false'
      : trackingProperties?.atCCeVar48,
    atCCeVar48: getAtCCevar48(),
    clickTrack: trackingProperties?.clickTrack ?? true,
    impressionTrack: trackingProperties?.impressionTrack ?? true,
    impressionCount: trackingProperties?.impressionCount ?? true,
    isAuth: isAuth,
    merchandisingCategory: metaData ? metaData?.type : trackingProperties?.merchandisingCategory,
    isDCAEnabled: true,
    // only pick when we have UXL response
    spid: getSPID({ metaData: metaData }),
    rpcCode: metaData?.rpcCode || '',
    cell: metaData?.cell || '',
    offerCode: metaData?.offerCode || '',
    type: metaData?.type || '',
  };
};
