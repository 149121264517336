import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Button } from '../../atoms/Button';
import { Heading } from '../../atoms/Heading';
import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';
import { Text } from '../../atoms/Text';
import { RichText } from '../RichText';
import { headingType, size, tags } from '../../utils/enums/enums';

import { IconBlockProps } from './IconBlock.types';
import { StyledIconBlock } from './IconBlock.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IconBlock = forwardRef((props: IconBlockProps, forwardedRef: any) => {
  const {
    variation,
    contentAlignment,
    openInNewTab,
    ctaLink,
    header,
    headerTag,
    descriptionText,
    ctaType,
    ctaLinkText,
    assetVariation,
    iconPath,
    dynamicMedia,
    trackingProperties,
    styleclass,
    componentId = '',
    openInNewWindowAriaLabel = '',
    iconType,
    isRichText = false,
    detailListItems,
    iconSize = 'large',
    headerFontSize = 't-subtitle-m',
    descriptionFontSize = 't-font-s',
  } = props;

  const renditions = dynamicMedia?.renditions;
  const [viewPort, setViewPort] = useState('Desktop');
  const isMobileView = viewPort === 'Mobile';
  const isTabletView = viewPort === 'Tablet';
  const headerFont = headerFontSize === 't-subtitle-l' ? size.large : size.medium;
  const desFontSize = descriptionFontSize === 't-font-xs' ? size.extraSmall : size.small;

  const handleResize = () => {
    const xs = window.matchMedia('(max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    const lg = window.matchMedia('(min-width: 992px)');
    if (xs?.matches) {
      setViewPort('Mobile');
    } else if (md?.matches) {
      setViewPort('Tablet');
    } else if (lg?.matches) {
      setViewPort('Desktop');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tertiaryBtnClass =
    ctaType === 'tertiaryLink'
      ? openInNewTab
        ? 'm-link-tertiary-button m-link-tertiary-button-external t-background-color'
        : 'm-link-tertiary-button t-background-color'
      : '';
  const secondaryBtnClass =
    ctaType === 'secondaryButton'
      ? openInNewTab
        ? 'm-button-s m-button-secondary m-button-external'
        : 'm-button-s m-button-secondary'
      : '';
  const primaryBtnClass =
    ctaType === 'primaryButton'
      ? openInNewTab
        ? 'm-button-s m-button-primary m-button-external'
        : 'm-button-s m-button-primary'
      : '';
  const isLinkValue = ctaType === 'tertiaryLink' ? true : false;
  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  const buttonCallback = () => {
    return isLinkValue ? {} : window.open(ctaLink, openInNewTab ? '_blank' : '_self');
  };

  return (
    <StyledIconBlock
      data-component-name="m-ui-library-IconBlock"
      data-testid="ui-library-IconBlock"
      className={clsx(
        assetVariation === 'iconfont' ? (isMobileView || isTabletView ? 'col-12' : 'col-4') : '',
        `p-0 ${styleclass ? styleclass : ''}`
      )}
      contentAlignment={contentAlignment}
      fontIcon={iconPath || ''}
      iconSize={iconSize}
    >
      <div className={`iconblock`} data-variation={variation} data-testid="icon-block" {...customAttributes}>
        {assetVariation === 'iconfont' && (
          <div className="iconblock-content iconblock-icon">
            <Icon
              iconClass={clsx(iconPath, iconType === 'iconDecorative' ? 'icon-decorative' : '', 'icon-custom')}
              ariaLabel={iconPath}
            />
          </div>
        )}
        {assetVariation === 'image' && (
          <div className="iconblock-content iconblock-img">
            <Image
              altText={dynamicMedia?.altText}
              renditions={renditions}
              dynamic={dynamicMedia?.dynamic}
              defaultImageURL={dynamicMedia?.assetPath}
            />
          </div>
        )}

        <div className="iconblock-body">
          <Heading
            element={headerTag}
            variation={headingType.subtitle}
            fontSize={headerFont}
            titleText={header || ''}
            disableCustomClickTrack
            customClass={'truncate-text heading m-0 '}
          />
          {descriptionText &&
            (isRichText ? (
              <RichText
                text={descriptionText}
                componentId={componentId}
                customClass={clsx('truncate-text', 'description', descriptionFontSize)}
              />
            ) : (
              <Text
                customClass={clsx('truncate-text', 'description')}
                copyText={descriptionText}
                fontSize={desFontSize}
                element={tags.div}
              />
            ))}

          {assetVariation === 'iconfont' && detailListItems && (
            <ul>
              {Array.isArray(detailListItems) &&
                detailListItems.slice(0, 5).map((item, index) => (
                  <li key={index}>
                    {item?.iconFontPath && (
                      <Icon
                        iconClass={item?.iconFontPath}
                        aria-live={item?.iconFontPath}
                        ariaLabel={item?.iconFontPath}
                      />
                    )}
                    {item?.text && (
                      <RichText
                        text={item?.text}
                        componentId={`${componentId}-descriptiontext`}
                        customClass="t-font-xs"
                      />
                    )}
                  </li>
                ))}
            </ul>
          )}
          {ctaLinkText && ctaLink && (
            <Button
              href={ctaLink}
              isLink={isLinkValue}
              className={`iconblock-button ${primaryBtnClass} ${secondaryBtnClass} ${tertiaryBtnClass}`}
              target={`${openInNewTab ? '_blank' : '_self'}`}
              linkType={`${openInNewTab ? 'external' : ''}`}
              rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
              ref={forwardedRef}
              buttonCopy={ctaLinkText}
              trackingProperties={trackingProperties}
              custom_click_track_value={
                trackingProperties?.clickTrack
                  ? `${trackingProperties?.location}|${trackingProperties?.trackingDescription}|${
                      openInNewTab ? 'external' : 'internal'
                    }`
                  : ''
              }
              isTextBeforeChildren={false}
              callback={buttonCallback}
              linkAriaLabelOpenNewWindow={openInNewWindowAriaLabel}
            />
          )}
        </div>
      </div>
    </StyledIconBlock>
  );
});
