import { Connection } from '../search/searchPropertiesByGeolocation.types';
import { EventCustomLink, ReslinkType } from './getReslinkEventById.types';

export type GetReslinkEventsForAssociateInput = {
  search: {
    key: SearchType;
    value: string;
    fromDate?: string;
    toDate?: string;
  };
  filter: {
    eventStatus: EventStatusType;
    association?: AssociationType;
  };
  reslinkType: ReslinkType;
};

export enum SearchType {
  EVENT_NAME = 'EVENT_NAME',
  MINI_HOTEL_CODE = 'MINI_HOTEL_CODE',
  CREATED_DATE = 'CREATED_DATE',
  START_DATE = 'START_DATE',
  PROPERTY_ID = 'PROPERTY_ID',
  ASSOCIATED_WITH = 'ASSOCIATED_WITH',
  CREATED_BY = 'CREATED_BY',
}

export enum AssociationType {
  CREATED_BY = 'CREATED_BY',
  ASSOCIATED_WITH = 'ASSOCIATED_WITH',
}

export enum EventStatusType {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export type GetReslinkEventsForAssociateResponse = Connection<ReslinkEventInfo> & {
  total: number;
};

export type ReslinkEventInfo = {
  id: string;
  localeURL: string;
  eventName: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  hotelCount: number;
  customImageURLs: EventCustomLink[];
};
