import { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Constants } from '../../utils/constant';
import { submitDynamicForm } from '../../utils/handler';
import { InternetBarAccessModalProps } from './InternetBarModal.types';
import { Button, InputTextField, useCheckBreakpoint } from '@marriott/mi-ui-library';
import { ButtonTypeVariation } from '../../../../modules/utils/enums/enums';

export const InternetBarAccessModal: FC<InternetBarAccessModalProps> = (props: InternetBarAccessModalProps) => {
  const { access, accessModal, internetBarDataValues, accessModalType } = props;
  const [returnToLogin, setReturnToLogin] = useState<string>('');

  const [accessCodeDetails, setAccessCodeDetails] = useState<string>('');

  const [accessModalError, setAccessModalError] = useState<boolean>(accessModalType === 'ACCESSERR' ? true : false);

  const [accessError, setAccessError] = useState({
    accessCodeError: false,
    showError: false,
  });
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isAccessOnly, setIsAccessOnly] = useState<boolean>(false);
  const isMobileAndAboveViewPort = useCheckBreakpoint('viewportS'); // check if viewport is Mobile and above

  const inputRef = useRef(null);

  const {
    MARSHA = '',
    postEndpoint = '',
    LR = '',
    Z = '',
    DOMAIN = '',
    MS,
    APS,
    zoneMapping = '',
    USAGE = '',
    DURATION = '',
    SP = '',
  } = internetBarDataValues || {};

  const handleAccessBtnClick = () => {
    setIsButtonLoading(true);
    if (postEndpoint && accessCodeDetails) {
      try {
        const obj = {
          MARSHA: MARSHA ? MARSHA : undefined,
          LR: LR,
          Z: Z,
          ACTION: 'CONNECTED',
          DOMAIN: DOMAIN ? DOMAIN : undefined,
          MS: MS || MS === '' ? MS : undefined,
          APS: APS,
          USAGE: USAGE ? USAGE : Constants.usage.ACCESS,
          SP: SP ? SP.toUpperCase() : Constants.plan.PLAN1,
          DURATION: DURATION ? DURATION : undefined,
          ACCESS_CODE: accessCodeDetails.trim(),
          TIERS: zoneMapping[Z] ?? undefined,
        };
        const body = JSON.stringify(obj);
        submitDynamicForm(body, postEndpoint);
      } catch (error) {
        // ignore
      }
    }
  };

  useEffect(() => {
    const handleQueryParamUpdate = (paramName: string, paramValue: string) => {
      const accessParams = new URLSearchParams(window.location.search);
      accessParams.set(paramName, paramValue);
      return `?${accessParams.toString()}`;
    };

    const hrefURL = handleQueryParamUpdate('MV', 'LOGIN');
    setReturnToLogin(hrefURL);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      isMobileAndAboveViewPort && inputRef && inputRef?.current && (inputRef?.current as HTMLInputElement)?.focus();
    }, 20);

    return () => clearTimeout(timeout);
  }, [isMobileAndAboveViewPort]);

  useEffect(() => {
    setIsAccessOnly(LR?.toUpperCase() === 'AF_AO');
  }, [LR]);

  return (
    <>
      {/* 1. Internet Bar Access Modal Default Configuration */}
      <div className="body-container d-flex flex-column">
        {/* 1.1 Internet Bar Access Modal Error */}
        {accessModal?.acErrorMessage && accessModalError && (
          <div className="d-flex pb-2">
            <span className="col-12 px-3 py-3 mb-4 internetbarmodal__error">{access?.acErrorMessage}</span>
          </div>
        )}
        <span className="t-subtitle-m pb-2">{access?.accessCodeTitle}</span>
        <span className="t-font-xs pb-4">{access?.requiredLabel}</span>
        <div className="m-form-group pb-5 mb-3">
          <InputTextField
            type="text"
            label={access?.accessCodeLabel}
            classNameForLineType="m-form-group mb-0"
            inputTextFieldClassName={`text-field t-font-m px-0 pb-2 ${
              accessError?.accessCodeError || accessModalError ? 'is-error' : ''
            }`}
            showIcon={false}
            getInputProps={() => ({
              ref: inputRef,
            })}
            variation="line-type"
            showUnderline={true}
            hideFontClass={true}
            assignInputValue={true}
            testId="access-code-input"
            onBlur={() => {
              if (accessCodeDetails?.length === 0 && (accessError?.showError || accessModalError)) {
                setAccessError({ ...accessError, accessCodeError: true });
              } else {
                setAccessError({ ...accessError, accessCodeError: false });
              }
            }}
            getInputValue={value => {
              setAccessCodeDetails(value);
              setAccessError({ ...accessError, accessCodeError: false });
              if (value?.length > 0) {
                setAccessError({ ...accessError, showError: true });
                if (accessModalError) {
                  setAccessModalError(false);
                }
              }
            }}
            onFocus={() => {
              setIsButtonLoading(false);
              setAccessError({ ...accessError, accessCodeError: false });
            }}
            showErrorMessage={accessError?.accessCodeError}
            inputValue={accessCodeDetails}
          />
        </div>
        <Button
          isLink={false}
          type={ButtonTypeVariation?.Button}
          isDisabled={accessCodeDetails?.length === 0 || isButtonLoading}
          className={clsx(
            `m-button-m m-button-primary t-font-m`,
            accessCodeDetails?.length === 0 || isButtonLoading ? 'disabled' : ''
          )}
          children={
            isButtonLoading ? (
              <div className="m-spinner-s" data-testid="activate-spinner"></div>
            ) : (
              access?.continueButtonLabel
            )
          }
          testId="access-btn"
          callback={handleAccessBtnClick}
        />
      </div>
      {!isAccessOnly ? (
        <div className="d-flex flex-column">
          {/* 2. Internet Bar Access Modal Separator Configuration  */}
          <span className="py-2 my-1 d-flex flex-row align-items-center justify-content-center">
            <span className="w-100 internetbarmodal__seperator"></span>
            <span className="px-2 mx-1 t-font-xs">{access?.acSeparatorLabel}</span>
            <span className="w-100 internetbarmodal__seperator"></span>
          </span>
          {/* 3. Internet Bar Access Modal Return Code Configuration  */}
          <div className="d-flex flex-column mx-auto text-center">
            <a className="t-font-xs internetbarmodal__link" href={returnToLogin}>
              {access?.returnLabel}
            </a>
          </div>
        </div>
      ) : null}
    </>
  );
};
