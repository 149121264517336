import { FC, useState, useEffect, Suspense, lazy } from 'react';
import clsx from 'clsx';

import { Types, Image, useWindowSize, baseVariables } from '@marriott/mi-ui-library';
import { useDCADynamicModel } from '@marriott/mi-headless-utils';
import { MerchandisingHeroBannerAssetProps } from './MerchandisingHeroBannerAsset/MerchandisingHeroBannerAsset.types';
import { MerchandisingHeroBannerSkeletonLoader } from './MerchandisingHeroBannerSkeletonLoader';
import {
  StyledMerchandisingHeroBannerContainer,
  StyledMerchandisingHeroBannerImage,
} from './MerchandisingHeroBanner.styles';
import { IMerchandisingHeroBanner } from './MerchandisingHeroBanner.types';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { URL_CONSTANTS, useMediaQuery } from '@marriott/shared/mi-helper-utils';
import { useDatalayer } from '@marriott/mi-headless-utils';
import { MerchandisingHeroBannerVariations } from '../../molecules/MerchandisingHeroBannerVariations';
import {
  LocationCTA,
  MerchandisingHeroBanner_Primary_Secondary_Buttons,
} from '../../molecules/MerchandisingHeroBannerButtons';
import { getTrackingPropertiesDynamic } from '../../utils/helpers';
import { MerchandisingHeroBannerContent } from '../../molecules/MerchandisingHeroBannerContent';

const MerchandisingHeroBannerVideo = lazy(() => import('./MerchandisingHeroBannerVideo/MerchandisingHeroBannerVideo'));
const MerchandisingHeroBannerAsset = lazy(() => import('./MerchandisingHeroBannerAsset/MerchandisingHeroBannerAsset'));

const { ImageAspectRatio, AssetVariation, Target, LinkTargetType, ScrimStyle, HeaderCopyWidth } = Types;
const { DEFAULT, EXTRATHIN, THIN, WIDE } = HeaderCopyWidth;
const { SIZE_16x9, SIZE_3x1, SIZE_2X1 } = ImageAspectRatio;
const { LIGHT_SCRIM } = ScrimStyle;
const { ENABLE_TARGET, DISABLE_TARGET } = Target;
const { VERTICAL_IMAGE } = AssetVariation;

export const MerchandisingHeroBanner: FC<IMerchandisingHeroBanner> = props => {
  const { targetData } = useDCADynamicModel(props?.mboxParameter);
  const { mboxParameter } = props;
  const {
    callToActionText1,
    destinationUrl1,
    primaryopeninanewtab,
    callToActionText2,
    destinationUrl2,
    secondaryopeninanewtab,
    caption,
    captionUrl,
    locationopeninanewtab,
    hideLocationCtaMobile,
    headerMedium,
    descriptionLong,
    eyebrowText,
    headertextstyle,
    heroSize,
    cobrandmathhero,
    mathLabel1,
    mathLabel2,
    mathLabel3,
    mathValue,
    mathType,
    assetsVariation,
    logo,
    font_icon,
    enableMaskingOnDesktop,
    imageLoading,
    appliedCssClassNames,
    enableTarget,
    trackingProperties,
    opensInNewTab,
    playerId,
    videoaccountId,
    videoIdDesktop,
    videoIdMobile,
    videoEnabledDesktop = Boolean(videoIdDesktop?.trim()),
    videoEnabledMobile = Boolean(videoIdMobile?.trim()),
    enableAutoPlay,
    enableVideoLoop,
    enableMute,
    fileReferenceVideoCoverImage,
    playerDomain = URL_CONSTANTS.BRIGHTCOVE_PLAYER_DOMAIN,
    aspectRatioMobileVideo,
    fileReferenceImageVertical,
    verticalImageAltText,
    fontIconAltText,
    image,
    componentId,
    copyBlockWidth = DEFAULT,
    isTargetContentLoaded = mboxParameter && !AuthoringUtils.isInEditor() ? false : true,
    isTargetContentError = false,
    metaData,
  } = props.mboxParameter && !AuthoringUtils.isInEditor() && targetData?.isTargetContentLoaded ? targetData : props;
  const { width } = useWindowSize();
  const { datalayer } = useDatalayer();

  const { assetPath, altText, dynamic, renditions } = image ?? {};

  const [hasMobileVideoError, setHasMobileVideoError] = useState(false);
  const [hasDesktopVideoError, setHasDesktopVideoError] = useState(false);
  const showVideoOnDesktopOnly =
    videoEnabledDesktop && videoEnabledMobile && !hasDesktopVideoError && hasMobileVideoError;

  const showVideoOnMobileOnly =
    (videoEnabledDesktop && videoEnabledMobile && hasDesktopVideoError && !hasMobileVideoError) ||
    (!videoEnabledDesktop && videoEnabledMobile && !hasMobileVideoError);

  const showVideoOnDesktopAndMobile =
    (videoEnabledDesktop && !videoEnabledMobile && !hasDesktopVideoError) ||
    (videoEnabledDesktop && videoEnabledMobile && !hasDesktopVideoError && !hasMobileVideoError);

  const isVerticalImage = !!(assetsVariation && assetsVariation === VERTICAL_IMAGE && fileReferenceImageVertical);
  const isVerticalImageFromProps = !!(
    props.assetsVariation &&
    props.assetsVariation === VERTICAL_IMAGE &&
    props.fileReferenceImageVertical
  );
  const isCopyBlockWidthWide = !!(copyBlockWidth === WIDE);
  const isLightScrim = metaData?.theme ? metaData?.theme !== 'Dark' : appliedCssClassNames?.includes(LIGHT_SCRIM);
  const shouldEnableDesktopMasking = enableMaskingOnDesktop && (heroSize === SIZE_16x9 || heroSize === SIZE_2X1);

  const copyBlockWidthContainerClass: string =
    copyBlockWidth === DEFAULT || (width && width < 576)
      ? 'col-12 col-xl-5 col-sm-6'
      : clsx('break-word col-12', {
          'col-xl-3 col-sm-4': copyBlockWidth === EXTRATHIN,
          'col-xl-4 col-sm-5': copyBlockWidth === THIN,
          'col-xl-6 col-sm-7': copyBlockWidth === WIDE && !isVerticalImage,
          'col-xl-7 col-lg-8 col-md-7 pr-lg-0 pr-xl-2': copyBlockWidth === WIDE && isVerticalImage,
        });

  const merchandisingHeroBannerAssetProps: MerchandisingHeroBannerAssetProps = {
    assetsVariation,
    fileReferenceImageVertical,
    verticalImageAltText,
    fontIcon: font_icon || '',
    fontIconAltText: fontIconAltText || '',
    heroSize,
    imgAltText: logo?.altText,
    logoImage: logo?.imageSrc,
    cobrandmathhero,
    isCopyBlockWidthWide,
  };

  // form custom click track value for hero cta's
  const formCustomClickTrackValue = (label: string, openInNewtab?: boolean): string => {
    const { INTERNAL, EXTERNAL } = LinkTargetType;
    return trackingProperties?.clickTrack && !trackingProperties?.impressionTrack
      ? `${headerMedium ? headerMedium : descriptionLong}|${label}|${openInNewtab ? EXTERNAL : INTERNAL}`
      : '';
  };

  useEffect(() => {
    window.addEventListener('load', () => {
      const parentContainer = document?.querySelector('.herobanner-fullbleed');
      parentContainer?.classList.remove('target-background');
      parentContainer?.classList.remove('herobanner-ratio-' + heroSize?.replace(':', 'x'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isVariationsAvailable = mathType?.toLowerCase() === 'points' || mathType?.toLowerCase() === 'nights';
  const trackingPropertiesDynamic = getTrackingPropertiesDynamic(
    trackingProperties,
    metaData,
    datalayer?.['mr_prof_authentication_state'] === 'authenticated',
    isTargetContentError,
    props?.mboxParameter
  );

  /**
   * conditions for to render video
   */
  const isDesktopViewPort = useMediaQuery(baseVariables.mediaQuery.md);
  const shouldRenderDesktopVideo =
    (videoEnabledDesktop && !videoEnabledMobile && !hasDesktopVideoError) ||
    (videoEnabledDesktop && videoEnabledMobile && isDesktopViewPort && !hasDesktopVideoError);

  //Mobile video should render only when mobile video when enabled
  const shouldRenderMobileVideo = videoEnabledMobile && !isDesktopViewPort && !hasMobileVideoError;

  return (
    <StyledMerchandisingHeroBannerContainer
      data-testid="MerchandisingHeroBannerLatest"
      data-component-name="o-merch-MerchandisingHeroBanner"
      className={clsx(props.appliedCssClassNames, {
        hb__masking: shouldEnableDesktopMasking,
        'hb__video-enabled-desktop': showVideoOnDesktopAndMobile,
        'hb__video-enabled-desktop-only': showVideoOnDesktopOnly,
        'hb__video-enabled-mobile-only': showVideoOnMobileOnly,
        'hb-3x1-ratio': heroSize !== SIZE_16x9,
      })}
      id={componentId}
      shouldClipPathFor2x1={!showVideoOnDesktopAndMobile && !showVideoOnDesktopOnly} //we use clip-path for 2x1 hero (for min-width: 768px) but we should not apply it when video is enabled on desktop
    >
      <Suspense fallback={''}>
        {!isTargetContentLoaded ? (
          <MerchandisingHeroBannerSkeletonLoader
            width={width}
            copyBlockWidth={props.copyBlockWidth} //explicily to be read from props as initial width of elements depends on initial props
            copyBlockWidthContainerClass={copyBlockWidthContainerClass}
            isVerticalImage={isVerticalImageFromProps}
            eyebrowText={props.eyebrowText}
            heroSize={props.heroSize}
            cobrandmathhero={props.cobrandmathhero}
            descriptionLong={props.descriptionLong}
            callToActionText2={props.callToActionText2}
            callToActionText1={props.callToActionText1}
            headerMedium={props.headerMedium}
            appliedCssClassNames={props.appliedCssClassNames}
          />
        ) : (
          <div
            className={clsx(
              'hero-banner hb__scrim',
              enableTarget ? ENABLE_TARGET : DISABLE_TARGET,
              isLightScrim ? 'light-scrim' : 'dark-scrim'
            )}
          >
            <div className={clsx('hb-item hero-' + heroSize?.replace(':', 'x'), assetsVariation)}>
              <StyledMerchandisingHeroBannerImage>
                <Image
                  customClass={clsx('hb__img d-block', heroSize !== SIZE_16x9 ? 'hb-3x1-ratio' : '')}
                  defaultImageURL={assetPath}
                  altText={altText}
                  dynamic={dynamic}
                  renditions={renditions}
                  title={altText || 'Hero Image'}
                  loading={imageLoading}
                />
              </StyledMerchandisingHeroBannerImage>
              {Boolean(shouldRenderDesktopVideo || shouldRenderMobileVideo) && (
                <MerchandisingHeroBannerVideo
                  playerId={playerId}
                  videoaccountId={videoaccountId}
                  videoIdDesktop={videoIdDesktop}
                  videoIdMobile={videoIdMobile}
                  videoEnabledDesktop={videoEnabledDesktop}
                  videoEnabledMobile={videoEnabledMobile}
                  enableAutoPlay={enableAutoPlay}
                  enableVideoLoop={enableVideoLoop}
                  enableMute={enableMute}
                  fileReferenceVideoCoverImage={fileReferenceVideoCoverImage}
                  playerDomain={playerDomain}
                  location={headerMedium ? headerMedium : descriptionLong}
                  aspectRatioMobileVideo={aspectRatioMobileVideo}
                  clickTrack={trackingProperties?.clickTrack}
                  heroSize={heroSize}
                  hasDesktopVideoError={hasDesktopVideoError}
                  hasMobileVideoError={hasMobileVideoError}
                  setHasDesktopVideoError={setHasDesktopVideoError}
                  setHasMobileVideoError={setHasMobileVideoError}
                />
              )}
              <div
                className={clsx(
                  'hb__cnt-sec gradient',
                  !(isLightScrim || appliedCssClassNames?.includes('no-scrim')) ? copyBlockWidth : '',
                  { 'overlay-text-hero3x1': heroSize === SIZE_3x1 }
                )}
              >
                <div
                  className={clsx('container hb__cnt-ctr px-md-2 py-md-0 hero-' + heroSize?.replace(':', 'x'), {
                    vertical__image__Container: isVerticalImage,
                  })}
                >
                  {logo?.imageSrc && <MerchandisingHeroBannerAsset {...merchandisingHeroBannerAssetProps} />}
                  <div
                    className={clsx('hb__text-overlay', {
                      'col-12 col-lg-5 col-md-7 col-sm-12 d-flex flex-column text-center justify-content-center align-items-center d-md-block text-md-left':
                        isVerticalImage,
                    })}
                  >
                    <MerchandisingHeroBannerContent
                      assetsVariation={assetsVariation}
                      copyBlockWidthContainerClass={copyBlockWidthContainerClass}
                      logo={logo}
                      font_icon={font_icon}
                      eyebrowText={eyebrowText}
                      headerMedium={headerMedium}
                      headertextstyle={headertextstyle}
                      descriptionLong={descriptionLong}
                      heroSize={heroSize}
                      cobrandmathhero={cobrandmathhero}
                      isVerticalImage={isVerticalImage}
                      isLightScrim={isLightScrim}
                    />
                    {isCopyBlockWidthWide && !isVerticalImage && isTargetContentLoaded && isVariationsAvailable && (
                      <MerchandisingHeroBannerVariations
                        dataLayer={datalayer}
                        isLightScrim={isLightScrim}
                        mathLabel1={mathLabel1}
                        mathLabel2={mathLabel2}
                        mathLabel3={mathLabel3}
                        mathValue={mathValue}
                        mathType={mathType}
                      />
                    )}
                    {/* Primary and Secondary Cta Button, if this condition satisfies then only render this component */}
                    {Boolean(callToActionText1 || (!isVerticalImage && callToActionText2)) && (
                      <MerchandisingHeroBanner_Primary_Secondary_Buttons
                        primaryopeninanewtab={primaryopeninanewtab}
                        secondaryopeninanewtab={secondaryopeninanewtab}
                        cobrandmathhero={cobrandmathhero}
                        heroSize={heroSize}
                        opensInNewTab={opensInNewTab}
                        trackingProperties={trackingProperties}
                        isVerticalImage={isVerticalImage}
                        callToActionText1={callToActionText1}
                        callToActionText2={callToActionText2}
                        destinationUrl1={destinationUrl1}
                        destinationUrl2={destinationUrl2}
                        metaData={metaData}
                        isTargetContentError={isTargetContentError}
                        isLightScrim={isLightScrim}
                        trackingPropertiesDynamic={trackingPropertiesDynamic}
                        custom_click_track_value={formCustomClickTrackValue(callToActionText2, secondaryopeninanewtab)}
                      />
                    )}
                  </div>
                </div>
                {/* Location Cta Button */}
                {Boolean(caption) && (
                  <LocationCTA
                    locationopeninanewtab={locationopeninanewtab}
                    caption={caption}
                    hideLocationCtaMobile={hideLocationCtaMobile}
                    captionUrl={captionUrl}
                    compName={trackingProperties?.trackingContentPosition}
                    opensInNewTab={opensInNewTab}
                    custom_click_track_value={opensInNewTab}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Suspense>
    </StyledMerchandisingHeroBannerContainer>
  );
};
