import { FC, useEffect } from 'react';
import { Config, EditableComponent, MappedComponentProperties } from '@adobe/aem-react-editable-components';
import { CardHorizontalMini } from '@marriott/mi-ui-library';
import { BonvoyApplicationAdProps } from './BonvoyApplicationAd.types';
import { StyledBonvoyApplicationAd } from './BonvoyApplicationAd.styles';

export const BonvoyApplicationAd: FC<BonvoyApplicationAdProps> = (pageProps: BonvoyApplicationAdProps) => {
  useEffect(() => {
    const element = document?.getElementsByClassName(
      'minicardlink t-background-color icon-arrow-right-external t-font-alt-s custom_click_track track-completed'
    );
    if (element) {
      element?.[0]?.setAttribute(
        'data-custom_click_track_value',
        'PPV5 - Bonvoy Application Ad|Unlock extraordinary experiences with the Marriott Bonvoy™ app.|external'
      );
      element?.[0]?.setAttribute(
        'custom_click_track_value',
        'PPV5 - Bonvoy Application Ad|Unlock extraordinary experiences with the Marriott Bonvoy™ app.|external'
      );
      element?.[0]?.children?.[1]?.setAttribute('data-is-click-track', 'true');
    }
  }, []);
  return (
    <StyledBonvoyApplicationAd data-testid="cardhorizontalmini" data-component-name="o-ppv5-cardhorizontalmini">
      <div className="content-container">
        <div className="bonvoyapplication-ad standard">
          <section className="container-sm px-3 px-md-2 px-xl-2 mb-0 mt-3 mt-md-5 bonvoyapplication-ad__section">
            <div className="row">
              <div className="col-12 mb-0 mb-md-2 mb-lg-0">
                <CardHorizontalMini {...pageProps?.model} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </StyledBonvoyApplicationAd>
  );
};

export const BonvoyApplicationAdConfig = {
  emptyLabel: 'BonvoyApplicationAdComponent',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `mi-aem-common-spa/components/content/cardhorizontalmini`,
} as Config<MappedComponentProperties>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BonvoyApplicationAdEditable = (props: any) => {
  return (
    <div data-testid="bonvoy-app-editable" className="standard">
      {props?.cqPath?.includes('experiencefragment') ? (
        <BonvoyApplicationAd {...props} />
      ) : (
        <EditableComponent config={BonvoyApplicationAdConfig} {...props}>
          <BonvoyApplicationAd {...props} />
        </EditableComponent>
      )}
    </div>
  );
};
