import React, { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { Button, Heading, Text, RichText, Types } from '@marriott/mi-ui-library';
import { EmailFormProps, EmailFormData } from './EmailForm.types';
import { StyledEmailForm, StyledEmailMessage } from './EmailForm.styles';
import { TextFormField } from '../../../molecules';
import {
  BACKSPACE_KEY,
  MULTIPLE_EMAIL_REGEX,
  OPTIONAL_EMAIL_REGEX,
  MULTIPLE_EMAIL_EXTENDED_REGEX,
} from '../../../constants';
import { MESSAGE_MAX_LENGTH, SUBJECT_LINE_MAX_LENGTH } from '../../constants';

export const EmailForm: FC<EmailFormProps> = props => {
  const { emailLabels, emailData, showLoader, onSubmit } = props;
  const { heading, to, from, subjectLine, message, ctas, variant } = emailLabels;
  const { send, backToHubLabel, backToHubRedirectLink } = ctas;
  const { toValue, ccValue } = emailData || {};

  const router = useRouter();

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
    clearErrors,
  } = useForm<EmailFormData>({
    defaultValues: {
      emailTo: toValue || '',
      emailCc: ccValue || from.value,
      subject: subjectLine.value,
      message: message.messageBody || '',
    },
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    const messageElement = document.querySelector('#message-field > div') as HTMLDivElement | null;

    const keydownListener = (event: KeyboardEvent) => {
      const text = (event.currentTarget as HTMLDivElement)?.innerText;
      if (text.length >= MESSAGE_MAX_LENGTH && event.key !== BACKSPACE_KEY) {
        event.preventDefault();
      }
    };

    const clipboardListner = (event: ClipboardEvent) => {
      const messageInnerText = (event.currentTarget as HTMLDivElement)?.innerText.trim() || '';
      const copiedText = event.clipboardData?.getData('text/plain') || '';
      if (messageInnerText.length + copiedText.length > MESSAGE_MAX_LENGTH) {
        event.preventDefault();
      }
    };

    messageElement?.addEventListener('keydown', keydownListener);
    messageElement?.addEventListener('paste', clipboardListner);

    return () => {
      messageElement?.removeEventListener('keydown', keydownListener);
      messageElement?.removeEventListener('paste', clipboardListner);
    };
  }, []);

  const submitCallback = (data: EmailFormData) => {
    onSubmit(data);
  };

  const validateMessage = (msg: string) => {
    if (!msg) {
      return message.requiredError;
    }
    const parser = new DOMParser();
    const document = parser.parseFromString(msg, 'text/html');
    const text = document.body.textContent;
    if (!text?.trim()) {
      return message.requiredError;
    }
    return true;
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLDivElement>,
    field: ControllerRenderProps<EmailFormData, 'message'>
  ) => {
    clearErrors('message');
    field.onChange(event.target.innerHTML);
  };

  const fromFieldValidator = (value: string) => {
    if (variant === 'roominglist') {
      if (!value) {
        return from.requiredError;
      } else if (!MULTIPLE_EMAIL_REGEX.test(value)) {
        return from.invalidError;
      }
    } else {
      !value && resetField('emailCc');
      if (value.split(';').length > 1) {
        return from.multipleEmailError;
      } else if (!OPTIONAL_EMAIL_REGEX.test(value)) {
        return from.invalidError;
      }
    }
    return true;
  };

  const mailToField = (
    <Controller
      name="emailTo"
      control={control}
      rules={{
        required: `${to.requiredError}`,
        pattern: {
          value: MULTIPLE_EMAIL_EXTENDED_REGEX,
          message: `${to.invalidError}`,
        },
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={to.label}
          placeholder={to.placeholder}
          className="m-input-field mb-5 col-l-12"
          showErrorMessage={!!errors?.emailTo?.message}
          errorMessage={errors?.emailTo?.message}
          onChange={event => {
            field.onChange(event);
            clearErrors('emailTo');
          }}
        />
      )}
    />
  );

  const mailCcField = (
    <Controller
      name="emailCc"
      control={control}
      rules={{
        validate: fromFieldValidator,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={from.label}
          placeholder={from.placeholder}
          className="m-input-field mb-5 col-l-12"
          showErrorMessage={!!errors?.emailCc?.message}
          errorMessage={errors?.emailCc?.message}
          onChange={event => {
            field.onChange(event);
            clearErrors('emailCc');
          }}
        />
      )}
    />
  );

  const subjectLineField = (
    <Controller
      name="subject"
      control={control}
      rules={{
        required: `${subjectLine.requiredError}`,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={subjectLine.label}
          maxLength={SUBJECT_LINE_MAX_LENGTH}
          className="m-input-field mb-5 col-l-12"
          showErrorMessage={!!errors?.subject?.message}
          errorMessage={errors?.subject?.message}
          onChange={event => {
            field.onChange(event);
            clearErrors('subject');
          }}
        />
      )}
    />
  );

  const messageField = (
    <Controller
      name="message"
      control={control}
      rules={{
        validate: validateMessage,
      }}
      render={({ field, fieldState }) => (
        <>
          <RichText
            {...field}
            componentId="message-field"
            customClass="message"
            text={message.messageBody || ' '}
            contentEditable={true}
            onChange={event => handleMessageChange(event, field)}
          />
          {fieldState.error ? (
            <Text
              copyText={message.requiredError}
              customClass={`d-inline-block mt-1 error-text`}
              element={Types.tags.span}
              fontSize={Types.size.extraSmall}
            ></Text>
          ) : null}
        </>
      )}
    />
  );

  return (
    <StyledEmailForm>
      <Heading
        variation={Types.headingType.title}
        element={Types.tags.h1}
        titleText={heading}
        customClass="t-title-s mb-5 p-0 col-lg-10"
      />
      <form onSubmit={handleSubmit(submitCallback)}>
        <div>{mailToField}</div>
        <div>{mailCcField}</div>
        {subjectLine.maxCountText && (
          <div className="t-font-xs d-flex justify-content-end">{subjectLine.maxCountText}</div>
        )}
        <div>{subjectLineField}</div>
        <StyledEmailMessage className={clsx('mb-5', errors.message && 'invalid-message')}>
          <p className="t-font-alt-s mb-1 d-flex justify-content-between">
            <span className="message-label">{message.label}</span>
            <span className="t-font-xs">{message.maxCount}</span>
          </p>
          {messageField}
        </StyledEmailMessage>
        <div className="cta-container d-flex justify-content-end">
          {backToHubRedirectLink && (
            <Button
              buttonCopy={backToHubLabel}
              className="mt-2 mr-3 back-to-hub"
              target={'_Self'}
              callback={() => router.push(backToHubRedirectLink as string)}
            />
          )}
          <Button
            type={Types.ButtonTypeVariation.Submit}
            isDisabled={showLoader}
            className={clsx('m-button-primary', showLoader && 'skeleton-loader')}
          >
            {send}
          </Button>
        </div>
      </form>
    </StyledEmailForm>
  );
};
